import React from "react";
import "./FlexRow.scss";

const FlexRow = (props) => {
  const { style, className, children } = props;
  return (
    <div className={`flex-rows ${className}`} style={style}>
      {children}
    </div>
  );
};
export default FlexRow;
