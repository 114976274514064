import React,{useEffect,useState} from 'react'
import Navbar from '../NavBar/Index'
import { useParams,useNavigate } from 'react-router-dom'
import Footer from '../Footer/Footer'
import { MarginBottom } from '../Elements'
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation, A11y} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import styles from './ListingDetailsPage.module.css'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { FaFacebookSquare } from "react-icons/fa";
import { RiTwitterXLine } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { IoHomeSharp } from "react-icons/io5";
import { IoMdFlag } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import {FacebookShareButton,TwitterShareButton,WhatsappShareButton,LinkedinShareButton} from 'react-share';
import { IoCheckbox } from "react-icons/io5";
import axios from 'axios'
import { url } from '../../constData';
import ScrollTop from '../../hooks/ScrollTop'


function ListingDetailsPage() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [data,setData] = useState(null);
  const [amenities,setAmenities] = useState([]);

  const [details,setDetails] = useState([]);
  
  useEffect(() => {
    axios.get(`${url}/api/property/listing/${id}`)
    .then((res) => {
      console.log(res.data);
      setData(res.data)
      setDetails([
        {
          title:'PRICE',
          value:`$${res.data.price}`
        },
        {
          title:'BEDROOMS',
          value:`${res.data.bedrooms}`
        },
        {
          title:'BATHROOMS',
          value:`${res.data.bathrooms}`
        },
        {
          title:'SIZE',
          value:`${res.data.squareFeet} sqft`
        },
        {
          title:'PARKING',
          value:`${res.data.parkingType}`
        }
        ])

      // res?.data?.amenities.split(',').map((item,index) => {
      //   setAmenities((prev) => {
      //     return [...prev,item]
      //   })
      // })

      res?.data?.amenities.map((item,index) => {
        item.split(',').map((item,index) => {
          setAmenities((prev) => {
            return [...prev,item]
          })
        })})

    })
    .catch((err) => {
      console.log(err)
    })
  }, [])

  return (
    <>
      <ScrollTop/>
        <Navbar/>
        <div className={styles.container}>
            <MarginBottom className="mb-space-4" />
            <MarginBottom className="mb-space-2" />
            <div className='md-container'>
          <Swiper 
            style={{
              width: "100%",
              margin:'auto',
              height: "23vmax",
              padding:'0 5vmax'
            }}
            className={styles.swiperContainer}
            modules={[Navigation]}
            navigation={true}
            slidesPerView={'auto'}
            spaceBetween={30}
             >
              {
                data?.photos?.map((item,index) => {
                 return <SwiperSlide style={{
                width: "auto",
                height: "100%",
              }} >
              <img style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: "10px"
              }} src={item} alt="" />           
            </SwiperSlide>})}
            {/* <SwiperSlide 
            style={{
                width: "auto",
                height: "100%",
              }}
            >
            <img 
            style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "10px"
              }} 
            src="https://cdn.pixabay.com/photo/2023/12/11/17/50/ai-generated-8444178_1280.jpg" alt="" />
            </SwiperSlide>  */}
          </Swiper>
            </div>
            </div>
            <div className={styles.contentDiv}>
              <div className={styles.leftDiv}>
              <h3>{data?.title}</h3>
              <div className={styles.detailsDiv1}>
              {/* //foraddress */}
                <div className={styles.addDiv}> 
                  <p className={styles.title}>ADDRESS</p>
                  <p className={styles.value}>{data?.address}</p>
                </div>
                <div className={styles.otherDetais}>
                {details?.map((item,index) => {
                  
                    return <>{item.value != "" ?<div className={styles.contentWrapperr}>
                  <span className={styles.title}>{item.title}</span>
                  <span className={styles.value}>{item.value}</span>
                  </div>:<></>}</>
                })}
                </div>
              </div>
              <hr className={styles.divider}/>
              {amenities?.length > 0 && <>
              <div className={styles.detailsDiv2} 
              style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                gap:'1vmax',
                width:'100%'
                }}>
                <p className={styles.title}>PROPERTY AMENITIES</p>
                <div style={{width:'100%'}}>
                <div
                className={styles.amenitiesWrapper}
                style={{
                  display:'flex',
                  flexDirection:'row',
                  justifyContent:'flex-start',
                  alignItems:'flex-start',
                  gap:'1vmax',
                  flexWrap:'wrap',
                  width:'100%',
                  padding:'1vmax 1vmax',
                  backgroundColor: '#f4f3f3'
                }}
                >
                {amenities?.map((item,index) => {
                return (
                  <div className={styles.amenitiesContainer}>
                  <div><p className={styles.title}><IoCheckbox style={{color:'rgba(0, 136, 203, 0.8)',marginTop:'-0.15vmax'}}/></p></div>
                  <div><p className={styles.value}>{item}</p></div>
                  </div>)
                })}
                </div>
                </div>
              </div>
              <hr className={styles.divider}/></>}
              {data?.description && <>
              <div className={styles.detailsDiv2}>
              <p className={styles.title}>DESCRIPTION</p>
              <p className={styles.description}>
              {data?.description}
              </p>
              </div></>}
              </div>
              <div className={styles.rightDiv}>
              
                <button className={styles.applybtn}
                onClick={() => navigate(`/application/${id}`)}
                >Apply Now</button>
              
              <div className={styles.shareContainer}>
              <p>SHARE</p>
              <div className={styles.shareIcons}>
              <div>
              <FacebookShareButton url={`https://www.frently.ca/listing/${id}`}>
                <FaFacebookSquare style={{color:'#405898'}}/>
              </FacebookShareButton>
              </div>
              <div>
              <TwitterShareButton url={`https://www.frently.ca/listing/${id}`}>
                <RiTwitterXLine style={{color:'#000'}}/>
              </TwitterShareButton>
              </div>
              <div>
              <WhatsappShareButton url={`https://www.frently.ca/listing/${id}`}>
                <FaWhatsapp style={{color:'green'}}/>
              </WhatsappShareButton>
              </div>
              <div>
              <LinkedinShareButton url={`https://www.frently.ca/listing/${id}`}>
                <FaLinkedin style={{color:'#405898'}}/>
              </LinkedinShareButton>
              </div>
              </div>
              </div>
              <div className={styles.btnForTnRdiv}>
                <p><IoHomeSharp style={{fontSize:'1.2vmax'}}/> Get your tenant insurance!</p>
              </div>
              <div className={styles.btnForTnRdiv}>
                <p><IoMdFlag style={{fontSize:'1.2vmax'}}/> Report listing…</p>
              </div>
              </div>
            </div>
            <div className={styles.bannerContainer}>
              <div className={styles.TaglineContainer}>
                <p className={styles.bannerTagline}>Apply for free to this listing now</p>
                <p className={styles.bannerSubTagline}>Apply online to complete your credit check and application.</p>
              </div>
              <div className={styles.bannerButtonContainer}>
                <button className={styles.bookaTableBtn}
                onClick={() => navigate(`/application/${id}`)}
                >Apply Now</button>
            </div>  
        </div>
        <MarginBottom className="mb-space-4" />
        <div className={styles.footerContainer}>
        <div className={styles.contentWrapper}>
        <div className={styles.leftDivv}>
              <a href="#" target='_blank' className={styles.fb}><FaFacebookSquare /></a>
              <a href="#" target='_blank' className={styles.x}><RiTwitterXLine /></a>
              <a href="#" target='_blank' className={styles.insta}><FaInstagram /></a>
              <a href="#" target='_blank' className={styles.linkedin}><FaLinkedin /></a>
        </div>
        <div className={styles.rightDivv}>
        <p>Calling all landlords!</p>
        <button className={styles.signupbtn}
        onClick={() => navigate('/login')}
        >Sign up for free</button>
        </div>
        </div>
        <div className="footer-component">
      <div className="horizontal-line"></div>
      <div style={{ width: "88%", margin: "auto", padding: "20px 0"}}>
        <div className="footer-text" style={{color:'#000'}}>
          <div style={{ alignSelf: "flex-start" }}>© Frently Inc. All Rights Reserved.</div>
          <div style={{ alignSelf: "flex-end" }}>FAQs | Contact Us | Terms of Service</div>
        </div>
        {/* support@frently.ca */}
      </div>
    </div>
        </div>
    </>
  )
}

export default ListingDetailsPage