import React, { useEffect,useState } from "react";
import "./index.scss";
import LISTING_IMAGE from "../../assets/listing1.png";
import FRENTLY from "../../assets/Frently-logo.png";
import FRENTLYBLACK from "../../assets/Frently-logo-black.png";
import FLAG from "../../assets/flag.png";
import { useLocation, useNavigate } from "react-router-dom";
function Navbar() {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const regex = new RegExp("^/application/[^/]+$");
  const listingRegex = new RegExp("^/listing/[^/]+$");
  const editpropertyRegex = new RegExp("^/edit-property/[^/]+$");

  const isBlackLogoPage =
  location.pathname === "/listing-management" ||
  location.pathname === "/add-property" ||
  regex.test(location.pathname) ||
  editpropertyRegex.test(location.pathname);
  const [FRENTLYLogo, setFRENTLYLogo] = useState(isBlackLogoPage ? FRENTLYBLACK : FRENTLY);
  // setFRENTLYLogo(isBlackLogoPage ? FRENTLYBLACK : FRENTLY);

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      setUser(JSON.parse(sessionStorage?.getItem("user")));
      if (sessionStorage.getItem("token")) {
        setLoggedIn(true);
        const isBlackLogoPage =
      location.pathname === "/listing-management" ||
      location.pathname === "/add-property" ||
      regex.test(location.pathname) ||
      listingRegex.test(location.pathname) ||
      editpropertyRegex.test(location.pathname);
        setFRENTLYLogo(isBlackLogoPage && sessionStorage.getItem("token") ? FRENTLYBLACK : FRENTLY);
      }
    } else {
      setLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById("header");
      // seting logo black for listing management page , add property page, application page and edit property page
      // for rest it will be white and black based on scroll
      const isBlackLogoPage =
        location.pathname === "/listing-management" ||
        location.pathname === "/add-property" ||
        regex.test(location.pathname) ||
        listingRegex.test(location.pathname) ||
        editpropertyRegex.test(location.pathname);

        setFRENTLYLogo(isBlackLogoPage && isLoggedIn() || !isLoggedIn() ? FRENTLYBLACK : !isBlackLogoPage && window.scrollY > 0 ? FRENTLYBLACK : FRENTLY);

      header.classList.toggle("header-scrolled", window.scrollY > 0);
    };
    
    const isLoggedIn = () => {
      if (sessionStorage.getItem("token")) {
        return true;
      } else {
        return false;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      id="header"
      className={
        location.pathname === "/listing-management"
          ? "fixed-top d-flex align-items-center header-transparent listingm-nav"
          : location.pathname === "/add-property"
          ? "fixed-top d-flex align-items-center header-transparent listingm-nav"
          : regex.test(location.pathname)
          ? "fixed-top d-flex align-items-center header-transparent application-nav"
          :listingRegex.test(location.pathname)? "fixed-top d-flex align-items-center header-transparent btnblue"
          : editpropertyRegex.test(location.pathname)?"fixed-top d-flex align-items-center header-transparent listingm-nav":
          "fixed-top d-flex align-items-center header-transparent"
      }
    >
      <div id="navv" className="container-xl container-2xl d-flex align-items-center justify-content-between">
        <div className="logo me-auto" style={{paddingLeft:'1vmax'}}>
          <h1>
            <a onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
              <img src={FRENTLYLogo} />
              {/* <img src={"https://frentlyassets.s3.ca-central-1.amazonaws.com/homepage/Flag.png"} /> */}
            </a>
          </h1>
        </div>

        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <a className="nav-link " onClick={() => navigate("/listings")} style={{ cursor: "pointer" }}>
                Browse
              </a>
            </li>
            <li>
              <a href="#contact-us" className="nav-link " style={{ cursor: "pointer" }} onClick={() => navigate("/#contact-us")}>
                Contact Us
              </a>
            </li>
            <li>
              <a className="nav-link " style={{ cursor: "pointer" }}>
                Landlords
              </a>
            </li>
            <li>
              <a className="nav-link " style={{ cursor: "pointer" }}>
                Tenants
              </a>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
        {loggedIn ? (
          <a onClick={() => navigate("/listing-management")} className="profile-wrapper" style={{ cursor: "pointer" }}>
            <img src={user?.profilePic != null ? user?.profilePic : LISTING_IMAGE} />
            <p style={{color:'#33333'}}>{user?.name}</p>
          </a>
        ) : (
          <button onClick={() => navigate("/login")} className="book-a-table-btn" style={{ cursor: "pointer" }}>
            {" "}
            Apply Online{" "}
          </button>
        )}
        {/* <a href="#" className="book-a-table-btn">
          Apply Online
          </a> */}
      </div>
    </header>
  );
}

export default Navbar;
