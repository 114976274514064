import React from "react";
import { Routes, Route } from "react-router-dom";
import { routePaths } from "../../routes/routes";
import IndexPage from "../IndexPage/IndexPage";
import LandingPage from "../BrowsePage/Index";
import LoginPage from "../LoginPage/Index";
import ListingManagementPage from "../ListingManagementPage/ListingManagementPage";
import AddPropertyPage from "../AddPropertyPage/AddPropertyPage";
import ApplicationPage from "../ApplicationPage/ApplicationPage";
import ListingDetailsPage from "../ListingDetails/ListingDetailsPage";
import EditProperty from "../EditProperty/EditProperty";

const RouteContainer = () => {
  return (
    <Routes>
      <Route path={routePaths.root} element={<IndexPage />} />
      <Route path={routePaths.landingPage} element={<LandingPage />} />
      <Route path={routePaths.loginPage} element={<LoginPage />} />
      <Route path={routePaths.listingManagementPage} element={<ListingManagementPage />} />
      <Route path={routePaths.addPropertyPage} element={<AddPropertyPage/>} />
      <Route path={routePaths.applicationPage} element={<ApplicationPage/>} />
      <Route path={routePaths.listingdetails} element={<ListingDetailsPage/>} />
      <Route path={routePaths.editproperty} element={<EditProperty/>} />
    </Routes>
  );
};

export default RouteContainer;
