// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_default input[type=text] {
  width: 100%;
  height: 35px;
  text-align: justify;
  border-radius: 6px;
  padding: 0.8rem;
  box-sizing: border-box;
  background: transparent;
  border: solid 0.9px rgba(255, 255, 255, 0.19);
  background-color: rgba(255, 255, 255, 0.08);
  font-size: 11px;
  font-family: "Graphik-Medium";
  color: #ffffff;
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.3);
  transition: 0.3s;
}
.input_default input[type=text]:focus {
  outline: none;
}
.input_default input[type=text]:hover {
  border: 0.3px solid rgb(57, 165, 233);
}
.input_default input[type=text]::placeholder {
  font-family: "Graphik-Medium";
  color: #373737;
  color: #ffffff;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
}

.marginSpace {
  margin-bottom: 0.4rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Elements/InputControls/InputText/InputText.scss","webpack://./src/components/variables.scss"],"names":[],"mappings":"AAII;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,sBAAA;EACA,uBAAA;EACA,6CAAA;EACA,2CAAA;EACA,eAAA;EACA,6BCXK;EDYL,cCdA;EDeA,4CAAA;EACA,gBAAA;AAHR;AAIQ;EACI,aAAA;AAFZ;AAIQ;EACI,qCAAA;AAFZ;AAIQ;EACI,6BCtBC;EDuBD,cAAA;EACA,cC1BJ;ED2BI,eAAA;EACA,gBAAA;EACA,kBAAA;AAFZ;;AAOA;EACI,qBAAA;AAJJ","sourcesContent":["@import \"../../../../components/variables.scss\";\n@import \"../../../../components/mixins.scss\";\n\n.input_default {\n    input[type=\"text\"] {\n        width: 100%;\n        height: 35px;\n        text-align: justify;\n        border-radius: 6px;\n        padding: 0.8rem;\n        box-sizing: border-box;\n        background: transparent;\n        border: solid 0.9px rgba(255, 255, 255, 0.19);\n        background-color: rgba(255, 255, 255, 0.08);\n        font-size: 11px;\n        font-family: $mediumFont;\n        color: $white;\n        box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.3);\n        transition: 0.3s;\n        &:focus {\n            outline: none;\n        }\n        &:hover {\n            border: 0.3px solid rgb(57, 165, 233);\n        }\n        &::placeholder {\n            font-family: $mediumFont;\n            color: #373737;\n            color: $white;\n            font-size: 11px;\n            font-weight: 500;\n            font-style: normal;\n        }\n    }\n}\n\n.marginSpace {\n    margin-bottom: 0.4rem;\n}\n","$primaryColor: hsl(0, 0%, 22%);\n$blueColor: #329FD4;\n$white: #ffffff;\n$black: #000000;\n$mediumFont: \"Graphik-Medium\";\n$regularFont: \"Graphik-Regular\";\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
