import React from "react";
import "./MegaTitle.scss";

const MegaTitle = (props) => {
  const { className, style, children } = props;
  return (
    <div className={`mega-title ${className}`} style={style}>
      {children}
    </div>
  );
};

export default MegaTitle;
