// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frently-about-steps {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 576px) {
  .frently-about-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Templates/AboutSteps/AboutSteps.scss","webpack://./src/components/mixins.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,mBAAA;AAAJ;AACI;EAHJ;ICOE,aAAA;IACA,sBAAA;IDHM,mBAAA;EAGN;AACF","sourcesContent":["@import \"../../../components/mixins.scss\";\n.frently-about-steps {\n    display: flex;\n    flex-direction: row;\n    @media only screen and (max-width: 576px) {\n        @include display-column;\n        align-items: center;\n     }\n}\n","@import \"./variables.scss\";\n@mixin align-center {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@mixin display-column {\n  display: flex;\n  flex-direction: column;\n}\n\n@mixin display-row {\n  display: flex;\n  flex-direction: row;\n}\n@mixin row-space-between {\n  flex-direction: row;\n  display: flex;\n  justify-content: space-between;\n}\n\n@mixin inputBox(\n  $width: 98%,\n  $height: 2.3rem,\n  $textAlign: justify,\n  $boxSize: border-box,\n  $background: transparent,\n  $bgColor: #79a0da,\n  $border: none,\n  $webkitShadow: 0 0px 0px 0px rgba(0, 0, 0, 0.3),\n  $color: #ffff,\n  $mdMarginBottom: 0.7rem\n) {\n  input[type=\"text\"] {\n    width: $width;\n    height: $height;\n    text-align: $textAlign;\n    box-sizing: $boxSize;\n    border-radius: 6px;\n    background: $background;\n    background-color: $bgColor;\n    border: $border;\n    -webkit-box-shadow: $webkitShadow;\n    box-shadow: $webkitShadow;\n    color: $color;\n  }\n  & ::placeholder {\n    color: #ffff;\n  }\n}\n@mixin font-normal {\n  font-size: 11px;\n  line-height: 1.55;\n  color: #373737;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  letter-spacing: normal;\n  font-family: $regularFont;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
