// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/Icons/download.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../public/Icons/attach.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-rows {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media only screen and (max-width: 576px) {
  .flex-rows {
    width: 100%;
    flex-direction: column;
  }
}
.flex-rows .icon-text {
  width: 89%;
  display: flex;
  justify-content: space-around;
}
.flex-rows .icon-text .icon-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.flex-rows .icon-text .icon-container .icon-download {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 20px;
  width: 20px;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
}
.flex-rows .icon-text .icon-container .icon-attach {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  height: 20px;
  width: 20px;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
}
.flex-rows .icon-text p {
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/components/Layouts/FlexRow/FlexRow.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACJ;AAAI;EALJ;IAMM,WAAA;IACA,sBAAA;EAGJ;AACF;AAFI;EACE,UAAA;EACA,aAAA;EACA,6BAAA;AAIN;AAHM;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;AAKR;AAHQ;EACE,mDAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,4BAAA;EACA,wBAAA;AAKV;AAHQ;EACE,mDAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,4BAAA;EACA,wBAAA;AAKV;AAFM;EACE,sBAAA;AAIR","sourcesContent":[".flex-rows {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    @media only screen and (max-width: 576px) {\n      width: 100%;\n      flex-direction: column;\n    }\n    .icon-text {\n      width: 89%;\n      display: flex;\n      justify-content: space-around;\n      .icon-container {\n        display: flex;\n        height: 100%;\n        justify-content: center;\n        align-items: center;\n  \n        .icon-download {\n          background: url(\"../../../../public/Icons/download.png\");\n          height: 20px;\n          width: 20px;\n          display: block;\n          background-repeat: no-repeat;\n          background-size: contain;\n        }\n        .icon-attach {\n          background: url(\"../../../../public/Icons/attach.png\");\n          height: 20px;\n          width: 20px;\n          display: block;\n          background-repeat: no-repeat;\n          background-size: contain;\n        }\n      }\n      p {\n        box-sizing: border-box;\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
