// import ListingData from "./Data";
import Banner from "../../assets/banner.png";
import "./index.scss";
import LISTING_IMAGE from "../../assets/listing1.png";
import FRENTLY from "../../assets/frently.png";
import FLAG from "../../assets/flag.png";
import Navbar from "../NavBar/Index";
import LEASED_LABEL from "../../assets/leasedLabel.png";
import { CircularProgress } from "@mui/material";
import { useState,useEffect } from "react";
import axios from "axios";
import { url } from "../../constData.js";
import { useNavigate } from "react-router-dom";
import ScrollTop from "../../hooks/ScrollTop.js";
import Footer from "../Footer/Footer.jsx";



const LandingPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [ListingData,setListingData]=useState(null);
  const [filterData,setFilterData]=useState([]);
  const [searchBy,setSearchBy]=useState('');
  const [address,setAddress]=useState('');
  const [propertyType,setPropertyType]=useState('');
  const [price,setPrice]=useState('');
  const [bathrooms,setBathrooms]=useState('');
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(()=>{
    axios.get(`${url}/api/property/get/allproperties`)
    .then(res=>{
        setListingData(res.data);
    })
    .catch(err=>{
        console.log(err);
    })

},[])   
  return (
    <div>
      {/* <header
        id="header"
        className="fixed-top d-flex align-items-center header-transparent"
      >
        <div className=" container-xl d-flex align-items-center justify-content-between">
          <div className="logo me-auto">
            <h1>
              <a href="index.html">
                <img src={FRENTLY} />
                <img src={FLAG} />
              </a>
            </h1>
          </div>

          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li>
                <a className="nav-link scrollto" href="#">
                  FAQs
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#">
                  Listing
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#">
                  Terms of Services
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>

          <a href="#" className="book-a-table-btn scrollto">
            Landlord
          </a>
        </div>
      </header> */}
      <ScrollTop />
      <Navbar />
      <section id="hero" className="head-section" >
        <div class="hero-container">
          <div
            id="heroCarousel"
            data-bs-interval="5000"
            class="carousel slide carousel-faded"
            data-bs-ride="carousel"
            style={{height:'40vh!important'}}
          >
            

            <div class="carousel-inner" role="listbox">
              <div class="carousel-item active header-bg">
                <div class="carousel-container">
                  <div class="carousel-content" >
                    <h2>
                      A Frently way to list,
                      <br/>
                      rent and view properties.
                    </h2>
                    {/* <div
                      id="form_banner"
                      class="form_banner"
                    >
                      <input
                        type="text"
                        style={{ width: 340 }}
                        class="form-control"
                        name=""
                        value="Search by address, city, university etc."
                        placeholder="Search by address, city, university etc."
                      />
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected="">Property type</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected="">Price range</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected="">Bathrooms</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        value="Waterloo, ON"
                        placeholder="Waterloo, ON"
                      />
                      <a href="#" class="book-a-table-btnn scrollto">
                        Search
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-us">
        <div className="container-xl container-2xl">
          <div class="section-title" style={{ textAlign: "left" }}>
            <h2>Popular Listings</h2>
          </div>
          <div className="row g-6 gy-4 mt-2">
            {ListingData?.length > 0 ?(
              ListingData?.map((item, index) => (
                <div key={index} className="col-lg-3 ">
                  <div className="card" style={{ fontFamily:'Graphik-Regular'}} >
                  {item.isLeased === true &&<img src={LEASED_LABEL} className="leased-label" alt="Leased" />}
                    <img
                      src={item?.photos[0]}
                      className="card-img-top"
                      style={{ height: "200px", objectFit: "cover" }}
                      alt="Property Image"
                      onClick={()=>{
                        navigate(`/listing/${item._id}`);
                  }}
                    />
                    <div className="card-body">
                      <p className="card-text clamp-2" style={{fontSize:'16px',minHeight:'3vmax',lineClamp:'1',lineBreak:'anywhere'}} onClick={()=>{
                    navigate(`/listing/${item._id}`);
                  }}>{item.title}</p>
                      <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        ${item.price}
                      </div>
                      <a onClick={()=>{
                      navigate(`/application/${item._id}`)
                      }} className="book-a-table-btnn scrollto">
                        Apply Now
                      </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))) : ListingData===null ? (
                <div className="container my-2 d-flex justify-content-center">
                  <CircularProgress />
                </div>
                  ):
                (<div className="container my-2 d-flex justify-content-center">
                  <h1>No Listings Yet</h1>
                </div>
              )}

          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
};

export default LandingPage;
