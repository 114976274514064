import React, { ReactNode } from "react";
import "./SquareBox.scss";

const SquareBox = (props) => {
  const { style, className, url, desc, children } = props;
  return (
    <div className={`frently-history ${className}`} style={style}>
      <div className="frently-box">
        <div className="frently-number">
          <img src={url} />
        </div>
        <div className="frently-text">{desc}</div>
      </div>
    </div>
  );
};
export default SquareBox;
