// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mb-space-1 {
  margin-bottom: 2rem;
}

.mb-space-2 {
  margin-bottom: 4rem;
}

.mb-space-3 {
  margin-bottom: 5rem;
}

.mb-space-4 {
  margin-bottom: 6rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Elements/MarginBottom/MarginBottom.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AACA;EACI,mBAAA;AAEJ;;AAAA;EACI,mBAAA;AAGJ;;AADA;EACI,mBAAA;AAIJ","sourcesContent":[".mb-space-1{\n    margin-bottom: 2rem;\n}\n.mb-space-2{\n    margin-bottom: 4rem;\n}\n.mb-space-3{\n    margin-bottom: 5rem;\n}\n.mb-space-4{\n    margin-bottom: 6rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
