import React, { useState, useEffect } from "react";
import "./index.scss";
import LISTING_IMAGE from "../../assets/listing1.png";
import LEASED_LABEL from "../../assets/leasedLabel.png";
// import ListingData from "./Data.js";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { url } from "../../constData.js";
import { FaRegEdit } from "react-icons/fa";
import Button from '@mui/material/Button';

function ListingsPage(props) {
  const navigate = useNavigate();
  const [ListingData, setListingData] = useState(null);
  const [totalApplications, setTotalApplications] = useState(0);
  const id = JSON.parse(sessionStorage.getItem("user"))?._id;

  useEffect(() => {
    axios.get(`${url}/api/property/get/user/properties/${id}`)
      .then((res) => {
        setListingData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
     const fetchTotalApplications = async () => {
      const res = await axios.get(`${url}/api/property/totalapplications/${id}`);
      setListingData(res?.data); 
    }
    axios.get(`${url}/api/property/totalapplications/${id}`)
    .then((res) => {
      setTotalApplications(res.data.length);
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  const fetchApplicantCountOnApplication = async (id) => {
    const res = await axios.get(`${url}/api/property/applications/${id}`);
    return res?.data?.length;
  };

  return (
    <div style={{ position: "relative",margin:'0' }}>
      <section className="why-us" style={{padding:'0'}}>
        <div className="container">
          <div class="section-title">
            <h2 style={{ textAlign: "left" }}>My Listings </h2>
            <span class="book-a-table-btnn" onClick={() => navigate("/add-property")}>
              Add Property 
            </span>
          </div>
          {/* this container is for total application on all properties */}
          <div className="row g-4 mt-2">
            <div className="col-lg-3 ">
              {totalApplications > 0 ? (
                <div className="card" style={{ fontFamily:'Graphik-Regular',minWidth:'18vmax'}}>
                  <div className="card-body">
                    <h5 className="card-title mb-0">Total Applications: {totalApplications}</h5>
                  </div>
                  </div>
              ):(<div className="card" style={{ fontFamily:'Graphik-Regular',minWidth:'18vmax'}}>
                  <div className="card-body">
                    <h5 className="card-title mb-0">Total Applications: 0</h5>
                  </div>
                  </div>)}
              </div>
              </div>

          <div className="row g-4 mt-2">
            {ListingData?.length > 0 ? (
              ListingData?.map((item, index) => (
                <div key={index} className="col-lg-3 ">
                  <div
                    className="card"
                    style={{ fontFamily:'Graphik-Regular'}}
                    
                  >
                    {item.isLeased === true && <img src={LEASED_LABEL} className="leased-label" alt="Leased" />}
                    <img src={item?.photos[0]} className="card-img-top" alt="Property Image" style={{ height: "200px", objectFit: "cover" }}
                      onClick={() => {
                      navigate(`/listing/${item._id}`);
                    }}
                    />
                    <div className="card-body">
                      <p className="card-text clamp-2" style={{fontSize:'16px',minHeight:'3vmax',lineClamp:'1',lineBreak:'anywhere'}}
                      onClick={() => {
                      navigate(`/listing/${item._id}`);
                    }}
                      >{item.title}</p>
                      <div className="d-flex justify-content-between align-items-center">
                      <p className="m-0">${item.price}</p>
                      <Button variant="outlined" size="small" style={{fontSize:'12px',borderRadius:'20px',padding:'0 10px',display:'flex',alignItems:'center',flexDirection:"row",height:'100%'}}
                      onClick={() => {
                      navigate(`/edit-property/${item._id}`);
                      }}
                      >
                      <span>Edit</span> <span><FaRegEdit style={{fontSize:'14px',marginLeft:'5px',marginTop:'-4px'}}/></span></Button>
                      </div>
                    </div>
                      <div className="d-flex justify-content-between align-items-center pt-2 card-footer">
                      <p className="m-0">Total Applications: </p>
                      <p className="m-0">{item?.Applications?.length||0}</p>
                      </div>
                  </div>
                </div>
              ))
            ) : ListingData===null ? (
              <div className="container my-2 d-flex justify-content-center">
                <div className="d-flex justify-content-center gap-2 align-items-center">
                  
                 <CircularProgress 
                  size={20}
                 />Loading...
                  </div>
              </div>
              
            ):(
              <div className="container my-2 d-flex justify-content-center">
                <h1>No Listings Yet</h1>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default ListingsPage;
