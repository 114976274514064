import React, { useEffect, useState } from "react";
import "./IndexPage.scss";
import { MegaTitle, CustomHeader, ImageSrcSet, PrimaryHeader, MarginBottom, Paragraph } from "../Elements";
import Footer from "../Footer/Footer";
import { SignUp, AboutSteps, FAQs, OperatorForm } from "../Templates";
import { Header, ContainerFluid, FlexRow, Container } from "../Layouts";
import Banner from "../../assets/Banner1.jpg";
import Navbar from "../NavBar/Index";
import LISTING_IMAGE from "../../assets/listing1.png";
// import ListingData from "./Data";
import LEASED_LABEL from "../../assets/leasedLabel.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { url } from "../../constData.js";
import ScrollTop from "../../hooks/ScrollTop.js";
import { CircularProgress } from "@mui/material";

const IndexPage = () => {
  const navigate = useNavigate();
  const [ListingData, setListingData] = useState([]);

  useEffect(() => {
    axios
      .get(`${url}/api/property/get/popularproperties`)
      .then((res) => {
        // console.log(res.data);
        setListingData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
    <ScrollTop />
      <Navbar />
      <div className="frently-main-container">
        <div className="frently-image-container">
          <div className="container-f">
            <div className="banner">
              <img className="banner-image" src={Banner} alt="Banner" />
              <div className="text-container">
                <div className="banner-title">
                  An all-new way to <br /> manage your Listings.
                </div>
                <div className="description">
                  List for free to access thousands of new tenants looking for a <br /> place to stay. A Frently way to list, rent and view properties.
                </div>

                <div className="sign-up-wrapper">
                  <SignUp />
                </div>

                {/* <div className="input-row">
                  <input className="Rectangle" type="email" placeholder="Email" />
                  <input className="Rectangle" type="tel" placeholder="Phone" />
                </div> */}
                {/* <button className="sign-up-button">Sign Up to List for Free</button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="frently-header-container">
          <div className="frently-image-desc">{/* <SignUp /> */}</div>
        </div>

        <MarginBottom className="mb-space-4" />

        <Container>
          <PrimaryHeader className="text-medium">
            Listing management shouldn’t be so hard.
            <br /> Try Frently for free.
          </PrimaryHeader>
          <MarginBottom className="mb-space-1" />
          <AboutSteps />
          <MarginBottom className="mb-space-1" />
          <section className="why-us ">
        <div className="container">
        <PrimaryHeader className="text-medium">
        <div class="section-title" style={{ textAlign: "left" }}>
            <h2>Popular Listings</h2>
          </div>
        </PrimaryHeader>
          
        <div className="mt-4">
  <div className="row gy-5">
  {ListingData?.length > 0 ?(
              ListingData?.map((item, index) => (
                <div key={index} className="col-lg-3">
                  <div className="card" style={{ fontFamily:'Graphik-Regular',minHeight:'320px'}} >
                  {item.isLeased === true &&<img src={LEASED_LABEL} className="leased-label" alt="Leased" />}
                    <img
                      src={item?.photos[0]}
                      className="card-img-top"
                      style={{ height: "200px", objectFit: "cover" }}
                      alt="Property Image"
                      onClick={()=>{
                        navigate(`/listings`);
                  }}
                    />
                    <div className="card-body" style={{padding:"10px"}}>
                      <p className="card-text clamp-2" style={{fontSize:'16px',lineClamp:'1',lineBreak:'anywhere'}} onClick={()=>{
                    navigate(`/listings`);
                  }}>{item.title}</p>
                      <div className="d-flex justify-content-between" >
                      <div  className="d-flex align-items-center">
                        ${item.price}
                      </div>
                      <a onClick={()=>{
                      navigate(`/application/${item._id}`)
                      }} className="book-a-table-btnn scrollto rounded">
                        Apply Now
                      </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))) : ListingData===null ? (
                <div className="container my-2 d-flex justify-content-center">
                  <CircularProgress />
                </div>
                  ):
                (<div className="container my-2 d-flex justify-content-center">
                  <h1>No Listings Yet</h1>
                </div>
              )}
  </div>
                <div className="d-flex justify-content-center mt-5 align-items-center">
                <div className="my-4 mb-4  pl-4 w-50"
                  style={{
                    color: "#000000",
                    backgroundColor: "#000000",
                    height: 1,
                    borderColor: "#000000",
                    opacity:0.2
                  }}
                ></div>
                  <button className="btn btn-primary px-4 py-2"
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#329fd4",
                    borderColor: "#329fd4",
                    borderRadius: '5px',
                    width: '140px',
                    height: '40px',
                    fontSize: '1rem',
                    fontWeight: 500,
                    padding: "4px 10px 4px 10px",
                    letterSpacing: 1,
                    transition: "all 0.3s ease",
                    cursor: "pointer",
                    outline: "none",
                  }}
                  onClick={()=>{
                    navigate(`/listings`);
                  }}
                  >
                    View More
                  </button>
                  <div className="my-4 w-50"
                  style={{
                    color: "#000000",
                    backgroundColor: "#000000",
                    height: 1,
                    borderColor: "#000000",
                    opacity:0.2
                  }}
                  ></div>
                  </div>
</div>

        </div>
      </section>
          <MarginBottom className="mb-space-3" />
          <FAQs />
          <a id="contact-us" className="anchor"></a>
          <MarginBottom className="mb-space-2" />
        </Container>
        <ContainerFluid>
          <Container>
            <FlexRow>
              <div className="operator-form-desc">
                <div className="container-xl">
                  <PrimaryHeader className="text-medium">
                    Sign Up for free to be a<br /> Host on Frently today. <br />
                  </PrimaryHeader>
                  <Paragraph className="support-text">Need help? Email support@frently.ca for assistance.</Paragraph>
                </div>
              </div>
              <div className="operator-form">
                <OperatorForm />
              </div>
            </FlexRow>
          </Container>
          <MarginBottom className="mb-space-4" />
          <Footer />
        </ContainerFluid>
        {/* <MarginBottom className="mb-space-4" /> */}
      </div>
    </>
  );
};

export default IndexPage;
