import React, { useState } from "react";
import "./ApplicationPage.scss";
import Navbar from "../NavBar/Index";
import { FaExclamationCircle, FaArrowAltCircleUp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { MdPhoneIphone } from "react-icons/md";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import { useNavigate, useParams } from "react-router-dom";
import { url } from "../../constData";
import axios from "axios";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import ScrollTop from "../../hooks/ScrollTop";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const ApplicationPage = () => {
  const navigate = useNavigate();
  const PropertyId = useParams().id;
  // const userId = JSON.parse(sessionStorage.getItem("user"))._id;
  const [prefMoveDate, setPrefMoveDate] = useState(null); //prefered move in date
  const [intPropAddress, setIntPropAddress] = useState(null); //interested property address
  const [offeredRentAmount, setOfferedRentAmount] = useState(null); //offered rent amount
  const [HasViewedUnit, setHasViewedUnit] = useState(""); //viewed unit
  const [viewingComments, setViewingComments] = useState(null); //viewing comments
  const [numOfParking, setNumOfParking] = useState(null); //number of parking
  const [NumberOfAdults, setNumberOfAdults] = useState("");
  const [NumberOfChildren, setNumberOfChildren] = useState("");
  const [OwnsPets, setOwnsPets] = useState(""); //own pets yes or no
  const [NumberOfDogs, setNumberOfDogs] = useState(""); //number of dogs
  const [NumberOfCats, setNumberOfCats] = useState("");
  const [DogBreeds, setDogBreeds] = useState("");
  const [OtherPets, setOtherPets] = useState("");

  const [CurrentLocationStatus, setCurrentLocationStatus] = useState(""); //current location status
  const [currentOtherLocation, setCurrentOtherLocation] = useState(""); //current other locantion status option location
  const [currentRentLandlordPhone, setCurrentRentLandlordPhone] = useState(""); //current rent landlord phone
  const [currentRentLandlordFName, setCurrentRentLandlordFName] = useState(""); //current rent landlord first name
  const [currentRentLandlordLName, setCurrentRentLandlordLName] = useState(""); //current rent landlord last name
  const [currentRentLandlordEmail, setCurrentRentLandlordEmail] = useState(""); //current rent landlord email
  const [currentRentAmount, setCurrentRentAmount] = useState(""); //current rent amount
  const [currentReasonForLeaving, setCurrentReasonForLeaving] = useState(""); //current reason for leaving
  const [currentGivenNotice, setCurrentGivenNotice] = useState(""); //current given notice

  const [previousAddress, setPreviousAddress] = useState(""); //previous address
  const [previousLandlordPhone, setPreviousLandlordPhone] = useState(""); //previous landlord phone
  const [previousLandlordFName, setPreviousLandlordFName] = useState(""); //previous landlord first name
  const [previousLandlordLName, setPreviousLandlordLName] = useState(""); //previous landlord last name
  const [previousLandlordEmail, setPreviousLandlordEmail] = useState(""); //previous landlord email

  const [Applicants, setApplicants] = useState([]);

  const [isCoSingned, setIsCoSingned] = useState(false);
  const [coSignnerDetails, setCoSignnerDetails] = useState({
    firstName: "",
    lastName: "",
    birthYear: "",
    birthMonth: "",
    birthDay: "",
    homePhone: "",
    cellPhone: "",
    email: "",
    placeOfEmployment: "",
    occupation: "",
    lengthofEmploymentYears: "",
    lengthofEmploymentMonths: "",
    employersTelephone: "",
    hourlyWage: "",
    yearlyWage: "",
    behalfOfApplicant: "",
    documents: [],
    docsPreview: [],
  });

  // console.log(coSignnerDetails);

  // console.log(Applicants);

  const [additionalExpectations, setAdditionalExpectations] = useState(""); //additional expectations

  const handleAdults = (e) => {
    setNumberOfAdults(e.target.value);

    if (e.target.value === "") {
      Applicants.splice(0, Applicants.length);
      return;
    }

    if (e.target.value === "1") {
      if (Applicants.length === 1) {
        return;
      } else {
        if (Applicants.length > 1) {
          Applicants.splice(1, Applicants.length);
        } else {
          Applicants.push({
            firstName: "",
            lastName: "",
            phone: "",
            cellPhone: "",
            email: "",
            birthYear: "",
            birthMonth: "",
            birthDay: "",
            currentAddress1: "",
            currentAddress2: "",
            currentCity: "",
            currentState: "",
            currentZip: "",
            placeOfEmployment: "",
            occupation: "",
            lengthofEmploymentYears: "",
            lengthofEmploymentMonths: "",
            employersTelephone: "",
            hourlyWage: "",
            yearlyWage: "",
            sinNumber: "",
            numberOfVehicles: "",
            bancruptcy: "",
            debtPurposal: "",
            payOnTime: "",
            afterForMoney: "",
            documents: [],
            docsPreview: [],
          });
        }
      }
    }

    if (e.target.value === "2") {
      if (Applicants.length === 2) {
        return;
      } else {
        if (Applicants.length > 2) {
          Applicants.splice(2, Applicants.length);
        } else {
          let howMany = 2 - Applicants.length;
          for (let i = 0; i < howMany; i++) {
            Applicants.push({
              firstName: "",
              lastName: "",
              phone: "",
              cellPhone: "",
              email: "",
              birthYear: "",
              birthMonth: "",
              birthDay: "",
              currentAddress1: "",
              currentAddress2: "",
              currentCity: "",
              currentState: "",
              currentZip: "",
              placeOfEmployment: "",
              occupation: "",
              lengthofEmploymentYears: "",
              lengthofEmploymentMonths: "",
              employersTelephone: "",
              hourlyWage: "",
              yearlyWage: "",
              sinNumber: "",
              numberOfVehicles: "",
              bancruptcy: "",
              debtPurposal: "",
              payOnTime: "",
              afterForMoney: "",
              documents: [],
              docsPreview: [],
            });
          }
        }
      }
    }

    if (e.target.value === "3") {
      if (Applicants.length === 3) {
        return;
      } else {
        if (Applicants.length > 3) {
          Applicants.splice(3, Applicants.length);
        } else {
          let howMany = 3 - Applicants.length;
          for (let i = 0; i < howMany; i++) {
            Applicants.push({
              firstName: "",
              lastName: "",
              phone: "",
              cellPhone: "",
              email: "",
              birthYear: "",
              birthMonth: "",
              birthDay: "",
              currentAddress1: "",
              currentAddress2: "",
              currentCity: "",
              currentState: "",
              currentZip: "",
              placeOfEmployment: "",
              occupation: "",
              lengthofEmploymentYears: "",
              lengthofEmploymentMonths: "",
              employersTelephone: "",
              hourlyWage: "",
              yearlyWage: "",
              sinNumber: "",
              numberOfVehicles: "",
              bancruptcy: "",
              debtPurposal: "",
              payOnTime: "",
              afterForMoney: "",
              documents: [],
              docsPreview: [],
            });
          }
        }
      }
    }

    if (e.target.value === "4") {
      if (Applicants.length === 5) {
        return;
      } else {
        if (Applicants.length > 4) {
          Applicants.splice(4, Applicants.length);
        } else {
          let howMany = 4 - Applicants.length;
          for (let i = 0; i < howMany; i++) {
            Applicants.push({
              firstName: "",
              lastName: "",
              phone: "",
              cellPhone: "",
              email: "",
              birthYear: "",
              birthMonth: "",
              birthDay: "",
              currentAddress1: "",
              currentAddress2: "",
              currentCity: "",
              currentState: "",
              currentZip: "",
              placeOfEmployment: "",
              occupation: "",
              lengthofEmploymentYears: "",
              lengthofEmploymentMonths: "",
              employersTelephone: "",
              hourlyWage: "",
              yearlyWage: "",
              sinNumber: "",
              numberOfVehicles: "",
              bancruptcy: "",
              debtPurposal: "",
              payOnTime: "",
              afterForMoney: "",
              documents: [],
              docsPreview: [],
            });
          }
        }
      }
    }
  };

  const submitApplication = async (e) => {
    // console.log(userId);
    // console.log(PropertyId);
    e.preventDefault();
    const formData = new FormData();

    // formData.append("userId", userId);
    formData.append("PropertyId", PropertyId);
    formData.append("prefMoveDate", prefMoveDate);
    formData.append("intPropAddress", intPropAddress);
    formData.append("offeredRentAmount", offeredRentAmount);
    formData.append("HasViewedUnit", HasViewedUnit);
    formData.append("viewingComments", viewingComments);
    formData.append("numOfParking", numOfParking);
    formData.append("NumberOfAdults", NumberOfAdults);
    formData.append("NumberOfChildren", NumberOfChildren);
    formData.append("OwnsPets", OwnsPets);
    formData.append("NumberOfDogs", NumberOfDogs);
    formData.append("NumberOfCats", NumberOfCats);
    formData.append("DogBreeds", DogBreeds);
    formData.append("OtherPets", OtherPets);
    formData.append("CurrentLocationStatus", CurrentLocationStatus);
    formData.append("currentOtherLocation", currentOtherLocation);
    formData.append("currentRentLandlordPhone", currentRentLandlordPhone);
    formData.append("currentRentLandlordFName", currentRentLandlordFName);
    formData.append("currentRentLandlordLName", currentRentLandlordLName);
    formData.append("currentRentLandlordEmail", currentRentLandlordEmail);
    formData.append("currentRentAmount", currentRentAmount);
    formData.append("currentReasonForLeaving", currentReasonForLeaving);
    formData.append("currentGivenNotice", currentGivenNotice);
    formData.append("previousAddress", previousAddress);
    formData.append("previousLandlordPhone", previousLandlordPhone);
    formData.append("previousLandlordFName", previousLandlordFName);
    formData.append("previousLandlordLName", previousLandlordLName);
    formData.append("previousLandlordEmail", previousLandlordEmail);
    formData.append("Applicants", Applicants);
    formData.append("isCoSingned", isCoSingned);
    formData.append("coSignnerDetails", coSignnerDetails);
    formData.append("additionalExpectations", additionalExpectations);

    console.log(formData);

    try {
      const res = await axios.post(
        `${url}/api/property/apply`,
        {
          // "userId": userId,
          PropertyId: PropertyId,
          prefMoveDate: prefMoveDate,
          intPropAddress: intPropAddress,
          offeredRentAmount: offeredRentAmount,
          HasViewedUnit: HasViewedUnit,
          viewingComments: viewingComments,
          numOfParking: numOfParking,
          NumberOfAdults: NumberOfAdults,
          NumberOfChildren: NumberOfChildren,
          OwnsPets: OwnsPets,
          NumberOfDogs: NumberOfDogs,
          NumberOfCats: NumberOfCats,
          DogBreeds: DogBreeds,
          OtherPets: OtherPets,
          CurrentLocationStatus: CurrentLocationStatus,
          currentOtherLocation: currentOtherLocation,
          currentRentLandlordPhone: currentRentLandlordPhone,
          currentRentLandlordFName: currentRentLandlordFName,
          currentRentLandlordLName: currentRentLandlordLName,
          currentRentLandlordEmail: currentRentLandlordEmail,
          currentRentAmount: currentRentAmount,
          currentReasonForLeaving: currentReasonForLeaving,
          currentGivenNotice: currentGivenNotice,
          previousAddress: previousAddress,
          previousLandlordPhone: previousLandlordPhone,
          previousLandlordFName: previousLandlordFName,
          previousLandlordLName: previousLandlordLName,
          previousLandlordEmail: previousLandlordEmail,
          Applicants: Applicants,
          isCoSingned: isCoSingned,
          coSignnerDetails: coSignnerDetails,
          additionalExpectations: additionalExpectations,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(res);
      if (res.status === 200) {
        alert("Application Submitted Successfully");
        // console.log(res.data);
        navigate("/listings");
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  // console.log(res);

  return (
    <>
      <ScrollTop />
      <Navbar />
      <div
        className=" application-container"
        style={{ backgroundColor: "rgba(234, 234, 234, 0.792)" }}
      >
        <div className="row">
          <div className="col-xs-12">
            <form onSubmit={submitApplication}>
              <fieldset
                className="section"
                style={{ borderColor: "#337ab7", borderWidth: "2px" }}
              >
                <label>
                  To speed up the application process, we require every
                  applicant that is over 18 years old to provide a{" "}
                  <b>full credit report, reflecting the score</b>. Please see
                  below for some free & paid websites to pull your own credit
                  report and self-submit. Thank-you, The Management.
                </label>
                <div className="row credit-links">
                  <div className="col-xs-6 col-md-4">
                    <a href="https://www.creditkarma.ca/" target="_blank">
                      www.creditkarma.ca
                    </a>
                  </div>
                  <div className="col-xs-6 col-md-4">
                    <a href="https://borrowell.com/" target="_blank">
                      www.borrowell.com
                    </a>
                  </div>
                  <div className="col-xs-6 col-md-4">
                    <a href="https://www.mogo.ca/" target="_blank">
                      www.mogo.ca
                    </a>
                  </div>
                  <div className="col-xs-6 col-md-4">
                    <a href="https://www.equifax.ca/" target="_blank">
                      www.equifax.ca
                    </a>
                  </div>
                  <div className="col-xs-6 col-md-4">
                    <a href="https://www.transunion.ca" target="_blank">
                      www.transunion.ca
                    </a>
                  </div>
                </div>
                {/* DEPOSIT MESSAGE */}
              </fieldset>

              <fieldset
                className="section"
                style={{ borderColor: "#a94442", borderWidth: "2px" }}
              >
                <p
                  style={{ color: "#8a6d3b", lineBreak: "anywhere" }}
                  className="warning-text"
                >
                  <FaExclamationCircle />
                  &nbsp; All units require tenant(s) to have an insurance policy
                  at move-in for all leaseholders. If you don't already have an
                  insurance provider, feel free to use our unique link below for
                  a discounted policy:{" "}
                  <a
                    href="https://www.squareone.ca/?offer_code=KWPMC"
                    target="_blank"
                  >
                    https://www.squareone.ca
                  </a>
                </p>
              </fieldset>

              <fieldset
                className="section"
                style={{ borderColor: "#a94442", borderWidth: "2px" }}
              >
                <p className="text-dangerr">
                  <FaExclamationCircle />
                  &nbsp; Please note that all applicants must view the unit in
                  order to process your application. A deposit will be required
                  upon approval of application and instructions will be shared
                  with you at that time.
                </p>
              </fieldset>

              <fieldset class="section" style={{ backgroundColor: "#fff" }}>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group required">
                      <label for="email">Preferred Move in Date</label>
                      <div class="input-group date" id="datetimepicker1">
                        <input
                          class="form-control"
                          id="PreferredMoveInDate"
                          maxlength="10"
                          name="PreferredMoveInDate"
                          placeholder="MM/DD/YYYY"
                          type="date"
                          value={prefMoveDate}
                          onChange={(e) => setPrefMoveDate(e.target.value)}
                        />
                        <span class="input-group-addon">
                          <span class="glyphicon glyphicon-calendar"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12">
                    <div class="form-group">
                      <label for="ViewingDetails">
                        Please specify the property{" "}
                        <mark>ADDRESS &amp; UNIT #</mark> you're interested in:
                      </label>
                      <textarea
                        cols="20"
                        id="PropertyDescription"
                        name="PropertyDescription"
                        placeholder="Property address"
                        rows="3"
                        style={{ width: "100%" }}
                        value={intPropAddress}
                        onChange={(e) => setIntPropAddress(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12">
                    <div class="form-group optional">
                      <label for="OfferedRentAmount">Offered Rent Amount</label>
                      <div class="input-group">
                        <span class="input-group-text">$</span>
                        <input
                          class="form-control"
                          id="OfferedRentAmount"
                          maxlength="10"
                          name="OfferedRentAmount"
                          placeholder="0.00"
                          type="number"
                          value={offeredRentAmount}
                          onChange={(e) => setOfferedRentAmount(e.target.value)}
                        />
                      </div>

                      <div class="error-msgs"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 ">
                    <label className="mb-2">Have you viewed the unit?</label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1vmax",
                        alignItems: "center",
                      }}
                    >
                      <label class="radio-inline">
                        <input
                          id="HasViewedUnit"
                          name="HasViewedUnit"
                          type="radio"
                          value={HasViewedUnit}
                          onChange={(e) => setHasViewedUnit(true)}
                        />{" "}
                        Yes
                      </label>
                      <label class="radio-inline">
                        <input
                          id="HasViewedUnit"
                          name="HasViewedUnit"
                          type="radio"
                          value={HasViewedUnit}
                          onChange={(e) => setHasViewedUnit(false)}
                        />{" "}
                        No
                      </label>
                      <div class="error-msgs"></div>
                    </div>
                  </div>
                </div>
                {HasViewedUnit === true && (
                  <div id="viewing-details" class="row">
                    <div class="col-xs-12">
                      <div class="form-group">
                        <label for="ViewingDetails">
                          Viewing comments: (optional)
                        </label>
                        <textarea
                          cols="20"
                          id="ViewingDetails"
                          name="ViewingDetails"
                          placeholder="Add any comments you may have regarding the viewing here."
                          rows="3"
                          style={{ width: "100%" }}
                          value={viewingComments}
                          onChange={(e) => setViewingComments(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                )}
              </fieldset>

              <div className="section-header">
                <h3>Personal Information</h3>
              </div>

              <fieldset className="section">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="NumberOfVehicles">
                        Required number of parking spaces?
                      </label>
                      <div>
                        <select
                          className="form-control form-select"
                          id="NumberOfVehicles"
                          name="NumberOfVehicles"
                          onChange={(e) => setNumOfParking(e.target.value)}
                        >
                          <option value=""></option>
                          <option value="0">None</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10+">10+</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Family size to occupy home</label>
                      <div className="row row-no-padding">
                        <div className="col-6">
                          <div className="form-inline family-controls">
                            <select
                              className="form-control form-select"
                              id="NumberOfAdults"
                              name="NumberOfAdults"
                              onChange={handleAdults}
                            >
                              <option value=""></option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                            </select>{" "}
                            &nbsp;&nbsp;&nbsp;Adults
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-inline family-controls">
                            <select
                              className="form-control form-select"
                              id="NumberOfChildren"
                              name="NumberOfChildren"
                              onChange={(e) =>
                                setNumberOfChildren(e.target.value)
                              }
                            >
                              <option value=""></option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10+</option>
                            </select>{" "}
                            &nbsp;&nbsp;&nbsp;Children
                          </div>
                        </div>
                      </div>
                      <div className="error-msgs"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <label>Do you own any pets?</label>
                    <div
                      className="form-group"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <label className="radio-inline">
                        <input
                          id="OwnsPets"
                          name="OwnsPets"
                          type="radio"
                          value={OwnsPets}
                          onChange={(e) => setOwnsPets("yes")}
                        />{" "}
                        Yes
                      </label>
                      <label className="radio-inline">
                        <input
                          id="OwnsPets"
                          name="OwnsPets"
                          type="radio"
                          value={OwnsPets}
                          onChange={(e) => setOwnsPets("no")}
                        />{" "}
                        No
                      </label>
                      <div className="error-msgs"></div>
                    </div>
                  </div>
                </div>
                {OwnsPets === "yes" && (
                  <div id="pets-wrapper" className="hidden">
                    <div className="form-group">
                      <div className="form-inline family-controls row">
                        <div className="col-xs-6 col-md-2">
                          <select
                            className="form-control form-select"
                            id="NumberOfDogs"
                            name="NumberOfDogs"
                            onChange={(e) => setNumberOfDogs(e.target.value)}
                          >
                            <option value=""></option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10+</option>
                          </select>{" "}
                          Dogs
                        </div>
                        <div className="col-xs-6 col-md-2">
                          <select
                            className="form-control form-select"
                            id="NumberOfCats"
                            name="NumberOfCats"
                            onChange={(e) => setNumberOfCats(e.target.value)}
                          >
                            <option value=""></option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10+</option>
                          </select>{" "}
                          Cats
                        </div>
                      </div>
                      <div className="error-msgs"></div>
                    </div>
                    {NumberOfDogs > 0 && (
                      <div className="form-group dog-details hidden">
                        <label for="DogBreeds">
                          Please indicate the breed of your dog(s)
                        </label>
                        <textarea
                          cols="20"
                          id="DogBreeds"
                          name="DogBreeds"
                          rows="3"
                          style={{ width: "100%" }}
                          onChange={(e) => setDogBreeds(e.target.value)}
                        ></textarea>
                      </div>
                    )}
                    <div className="form-group optional">
                      <label for="OtherPets">
                        Please list any other pets you own
                      </label>
                      <textarea
                        cols="20"
                        id="OtherPets"
                        name="OtherPets"
                        rows="3"
                        style={{ width: "100%" }}
                        onChange={(e) => setOtherPets(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                )}
              </fieldset>

              <div className="section-header">
                <h3>Rental History - Current Location</h3>
              </div>

              <fieldset className="section" style={{ marginBottom: "0vmax" }}>
                <div className="form-group">
                  <select
                    className="form-control form-select"
                    id="CurrentLocationStatus"
                    name="CurrentLocationStatus"
                    onChange={(e) => setCurrentLocationStatus(e.target.value)}
                  >
                    <option value="">Please specify...</option>
                    <option value="I am currently a home owner">
                      I am currently a home owner
                    </option>
                    <option value="I currently live with family">
                      I currently live with family
                    </option>
                    <option value="renting">I am currently renting</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                {CurrentLocationStatus === "other" && (
                  <div className="form-group tennant-history-other">
                    <label for="CurrentLocationStatusOther">
                      Please describe your current location
                    </label>
                    <input
                      className="form-control"
                      id="CurrentLocationStatusOther"
                      maxlength="255"
                      name="CurrentLocationStatusOther"
                      type="text"
                      value={currentOtherLocation}
                      onChange={(e) => setCurrentOtherLocation(e.target.value)}
                    />
                  </div>
                )}
                {CurrentLocationStatus === "renting" && (
                  <>
                    {" "}
                    <div className="row tennant-history-wrapper hidden">
                      <div className="col-sm-6">
                        <div className="form-group ">
                          <label for="LandlordPhone">
                            Current Landlord's Phone
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <IoIosCall />
                            </span>
                            <input
                              className="form-control"
                              id="LandlordPhone"
                              maxlength="50"
                              name="LandlordPhone"
                              type="number"
                              value={currentRentLandlordPhone}
                              onChange={(e) =>
                                setCurrentRentLandlordPhone(e.target.value)
                              }
                            />
                          </div>
                          <div className="error-msgs"></div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="LandlordFirstName">
                            Current Landlord's Name
                          </label>
                          <div className="row row-no-padding-sm ">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                id="LandlordFirstName"
                                maxlength="25"
                                name="LandlordFirstName"
                                placeholder="First"
                                type="text"
                                value={currentRentLandlordFName}
                                onChange={(e) =>
                                  setCurrentRentLandlordFName(e.target.value)
                                }
                              />
                            </div>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                id="LandlordLastName"
                                maxlength="25"
                                name="LandlordLastName"
                                placeholder="Last"
                                type="text"
                                value={currentRentLandlordLName}
                                onChange={(e) =>
                                  setCurrentRentLandlordLName(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="error-msgs"></div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group optional tennant-history-wrapper">
                      <label for="LandlordEmailAddress">
                        Current Landlord's Email address
                      </label>
                      <input
                        className="form-control"
                        id="LandlordEmailAddress"
                        maxlength="80"
                        name="LandlordEmailAddress"
                        placeholder="Enter email address"
                        type="email"
                        value={currentRentLandlordEmail}
                        onChange={(e) =>
                          setCurrentRentLandlordEmail(e.target.value)
                        }
                      />
                      <div className="error-msgs"></div>
                    </div>
                    <div className="form-group tennant-history-wrapper">
                      <label for="RentAmount">Current Rent Amount</label>
                      <div className="input-group">
                        <span className="input-group-text">$</span>
                        <input
                          className="form-control"
                          id="RentAmount"
                          maxlength="10"
                          name="RentAmount"
                          placeholder="0.00"
                          type="number"
                          value={currentRentAmount}
                          onChange={(e) => setCurrentRentAmount(e.target.value)}
                        />
                      </div>
                      <div className="error-msgs"></div>
                    </div>
                    <div className="form-group tennant-history-wrapper">
                      <label for="ReasonForLeaving">
                        Reason for Leaving Current Location
                      </label>
                      <textarea
                        cols="20"
                        id="ReasonForLeaving"
                        name="ReasonForLeaving"
                        rows="3"
                        style={{ width: "100%" }}
                        value={currentReasonForLeaving}
                        onChange={(e) =>
                          setCurrentReasonForLeaving(e.target.value)
                        }
                      ></textarea>
                    </div>
                    <div className="form-group tennant-history-wrapper">
                      <label>Have you given notice?</label>
                      <div
                        style={{
                          display: "flex",
                          gap: "2vmax",
                          alignItems: "center",
                        }}
                      >
                        <label className="radio-inline">
                          <input
                            id="GivenNotice"
                            name="GivenNotice"
                            type="radio"
                            value="True"
                            onChange={(e) => setCurrentGivenNotice(true)}
                          />{" "}
                          Yes
                        </label>
                        <label className="radio-inline">
                          <input
                            id="GivenNotice"
                            name="GivenNotice"
                            type="radio"
                            value="False"
                            onChange={(e) => setCurrentGivenNotice(false)}
                          />{" "}
                          No
                        </label>
                      </div>
                      <div className="error-msgs"></div>
                    </div>{" "}
                  </>
                )}
              </fieldset>

              <div className="section-header">
                <h3>Rental History - Past Location</h3>
              </div>
              <fieldset className="section">
                <div className="form-group" style={{ fontSize: "14px" }}>
                  If you rented in the past 3 years at a location other than
                  your current location, please specify below.
                </div>

                <div className="form-group">
                  <label for="PreviousAddress">Previous address</label>
                  <input
                    className="form-control"
                    id="PreviousAddress"
                    maxlength="255"
                    name="PreviousAddress"
                    type="text"
                    value={previousAddress}
                    onChange={(e) => setPreviousAddress(e.target.value)}
                  />
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label for="PreviousLandlordPhone">
                        Previous Landlord's Phone
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <IoIosCall />
                        </span>
                        <input
                          className="form-control"
                          id="PreviousLandlordPhone"
                          maxlength="50"
                          name="PreviousLandlordPhone"
                          type="text"
                          value={previousLandlordPhone}
                          onChange={(e) =>
                            setPreviousLandlordPhone(e.target.value)
                          }
                        />
                      </div>
                      <div className="error-msgs"></div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label for="LandlordFirstName">
                        Previous Landlord's Name
                      </label>
                      <div className="row row-no-padding-sm">
                        <div className="col-sm-6">
                          <input
                            className="form-control"
                            id="PreviousLandlordFirstName"
                            maxlength="25"
                            name="PreviousLandlordFirstName"
                            placeholder="First"
                            type="text"
                            value={previousLandlordFName}
                            onChange={(e) =>
                              setPreviousLandlordFName(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-sm-6">
                          <input
                            className="form-control"
                            id="PreviousLandlordLastName"
                            maxlength="25"
                            name="PreviousLandlordLastName"
                            placeholder="Last"
                            type="text"
                            value={previousLandlordLName}
                            onChange={(e) =>
                              setPreviousLandlordLName(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="error-msgs"></div>
                    </div>
                  </div>
                </div>
                <div className="form-group optional">
                  <label for="LandlordEmailAddress">
                    Previous Landlord's Email address
                  </label>
                  <input
                    className="form-control"
                    id="PreviousLandlordEmailAddress"
                    maxlength="80"
                    name="PreviousLandlordEmailAddress"
                    placeholder="Enter email address"
                    type="email"
                    value={previousLandlordEmail}
                    onChange={(e) => setPreviousLandlordEmail(e.target.value)}
                  />
                  <div className="error-msgs"></div>
                </div>
              </fieldset>

              <div id="applicant-wrapper" className="hidden">
                {Array.from(Applicants).map((applicant, index) => (
                  <>
                    <div className="section-header">
                      <h3>Applicant {index + 1} Details</h3>
                    </div>

                    {index === 0 && (
                      <div className="alert alert-warning" role="alert">
                        Please specify the preferred primary contact as
                        applicant 1 for any correspondence related to this
                        application. Thank you!
                      </div>
                    )}

                    <fieldset className="section">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="App2FirstName">Name</label>
                            <div className="row row-no-padding-sm">
                              <div className="col-sm-6">
                                <input
                                  className="form-control"
                                  id="Applicants_0__FirstName"
                                  maxlength="25"
                                  name="Applicants[0].FirstName"
                                  placeholder="First"
                                  type="text"
                                  value={applicant.firstName}
                                  onChange={(e) => {
                                    setApplicants((prev) => {
                                      prev[index].firstName = e.target.value;
                                      return [...prev];
                                    });
                                  }}
                                />
                              </div>
                              <div className="col-sm-6">
                                <input
                                  className="form-control"
                                  id="Applicants_0__LastName"
                                  maxlength="25"
                                  name="Applicants[0].LastName"
                                  placeholder="Last"
                                  type="text"
                                  value={applicant.lastName}
                                  onChange={(e) => {
                                    setApplicants((prev) => {
                                      prev[index].lastName = e.target.value;
                                      return [...prev];
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="error-msgs"></div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="birthday">Date of Birth</label>
                            <div className="row row-no-padding">
                              <div className="col-4">
                                <select
                                  className="form-control form-select"
                                  id="Applicants_0__BirthYear"
                                  name="Applicants[0].BirthYear"
                                  value={applicant.birthYear}
                                  onChange={(e) => {
                                    setApplicants((prev) => {
                                      prev[index].birthYear = e.target.value;
                                      return [...prev];
                                    });
                                  }}
                                >
                                  <option value="">YY</option>
                                  <option value="2023">2023</option>
                                  <option value="2022">2022</option>
                                  <option value="2021">2021</option>
                                  <option value="2020">2020</option>
                                  <option value="2019">2019</option>
                                  <option value="2018">2018</option>
                                  <option value="2017">2017</option>
                                  <option value="2016">2016</option>
                                  <option value="2015">2015</option>
                                  <option value="2014">2014</option>
                                  <option value="2013">2013</option>
                                  <option value="2012">2012</option>
                                  <option value="2011">2011</option>
                                  <option value="2010">2010</option>
                                  <option value="2009">2009</option>
                                  <option value="2008">2008</option>
                                  <option value="2007">2007</option>
                                  <option value="2006">2006</option>
                                  <option value="2005">2005</option>
                                  <option value="2004">2004</option>
                                  <option value="2003">2003</option>
                                  <option value="2002">2002</option>
                                  <option value="2001">2001</option>
                                  <option value="2000">2000</option>
                                  <option value="1999">1999</option>
                                  <option value="1998">1998</option>
                                  <option value="1997">1997</option>
                                  <option value="1996">1996</option>
                                  <option value="1995">1995</option>
                                  <option value="1994">1994</option>
                                  <option value="1993">1993</option>
                                  <option value="1992">1992</option>
                                  <option value="1991">1991</option>
                                  <option value="1990">1990</option>
                                  <option value="1989">1989</option>
                                  <option value="1988">1988</option>
                                  <option value="1987">1987</option>
                                  <option value="1986">1986</option>
                                  <option value="1985">1985</option>
                                  <option value="1984">1984</option>
                                  <option value="1983">1983</option>
                                  <option value="1982">1982</option>
                                  <option value="1981">1981</option>
                                  <option value="1980">1980</option>
                                  <option value="1979">1979</option>
                                  <option value="1978">1978</option>
                                  <option value="1977">1977</option>
                                  <option value="1976">1976</option>
                                  <option value="1975">1975</option>
                                  <option value="1974">1974</option>
                                  <option value="1973">1973</option>
                                  <option value="1972">1972</option>
                                  <option value="1971">1971</option>
                                  <option value="1970">1970</option>
                                  <option value="1969">1969</option>
                                  <option value="1968">1968</option>
                                  <option value="1967">1967</option>
                                  <option value="1966">1966</option>
                                  <option value="1965">1965</option>
                                  <option value="1964">1964</option>
                                  <option value="1963">1963</option>
                                  <option value="1962">1962</option>
                                  <option value="1961">1961</option>
                                  <option value="1960">1960</option>
                                  <option value="1959">1959</option>
                                  <option value="1958">1958</option>
                                  <option value="1957">1957</option>
                                  <option value="1956">1956</option>
                                  <option value="1955">1955</option>
                                  <option value="1954">1954</option>
                                  <option value="1953">1953</option>
                                  <option value="1952">1952</option>
                                  <option value="1951">1951</option>
                                  <option value="1950">1950</option>
                                  <option value="1949">1949</option>
                                  <option value="1948">1948</option>
                                  <option value="1947">1947</option>
                                  <option value="1946">1946</option>
                                  <option value="1945">1945</option>
                                  <option value="1944">1944</option>
                                  <option value="1943">1943</option>
                                  <option value="1942">1942</option>
                                  <option value="1941">1941</option>
                                  <option value="1940">1940</option>
                                  <option value="1939">1939</option>
                                  <option value="1938">1938</option>
                                  <option value="1937">1937</option>
                                  <option value="1936">1936</option>
                                  <option value="1935">1935</option>
                                  <option value="1934">1934</option>
                                  <option value="1933">1933</option>
                                  <option value="1932">1932</option>
                                  <option value="1931">1931</option>
                                  <option value="1930">1930</option>
                                  <option value="1929">1929</option>
                                  <option value="1928">1928</option>
                                  <option value="1927">1927</option>
                                  <option value="1926">1926</option>
                                  <option value="1925">1925</option>
                                  <option value="1924">1924</option>
                                  <option value="1923">1923</option>
                                  <option value="1922">1922</option>
                                  <option value="1921">1921</option>
                                  <option value="1920">1920</option>
                                  <option value="1919">1919</option>
                                  <option value="1918">1918</option>
                                  <option value="1917">1917</option>
                                  <option value="1916">1916</option>
                                  <option value="1915">1915</option>
                                  <option value="1914">1914</option>
                                  <option value="1913">1913</option>
                                  <option value="1912">1912</option>
                                  <option value="1911">1911</option>
                                  <option value="1910">1910</option>
                                  <option value="1909">1909</option>
                                  <option value="1908">1908</option>
                                  <option value="1907">1907</option>
                                  <option value="1906">1906</option>
                                  <option value="1905">1905</option>
                                  <option value="1904">1904</option>
                                </select>
                              </div>
                              <div className="col-4">
                                <select
                                  className="form-control form-select"
                                  id="Applicants_0__BirthMonth"
                                  name="Applicants[0].BirthMonth"
                                  value={applicant.birthMonth}
                                  onChange={(e) => {
                                    setApplicants((prev) => {
                                      prev[index].birthMonth = e.target.value;
                                      return [...prev];
                                    });
                                  }}
                                >
                                  <option value="">MM</option>
                                  <option value="0">Jan</option>
                                  <option value="1">Feb</option>
                                  <option value="2">Mar</option>
                                  <option value="3">Apr</option>
                                  <option value="4">May</option>
                                  <option value="5">Jun</option>
                                  <option value="6">Jul</option>
                                  <option value="7">Aug</option>
                                  <option value="8">Sep</option>
                                  <option value="9">Oct</option>
                                  <option value="10">Nov</option>
                                  <option value="11">Dec</option>
                                  <option value="12"></option>
                                </select>
                              </div>
                              <div className="col-4">
                                <select
                                  className="form-control form-select"
                                  id="Applicants_0__BirthDay"
                                  name="Applicants[0].BirthDay"
                                  value={applicant.birthDay}
                                  onChange={(e) => {
                                    setApplicants((prev) => {
                                      prev[index].birthDay = e.target.value;
                                      return [...prev];
                                    });
                                  }}
                                >
                                  <option value="">DD</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                  <option value="21">21</option>
                                  <option value="22">22</option>
                                  <option value="23">23</option>
                                  <option value="24">24</option>
                                  <option value="25">25</option>
                                  <option value="26">26</option>
                                  <option value="27">27</option>
                                  <option value="28">28</option>
                                  <option value="29">29</option>
                                  <option value="30">30</option>
                                  <option value="31">31</option>
                                </select>
                              </div>
                            </div>
                            <div className="error-msgs"></div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="HomePhone">Home Phone</label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <IoIosCall />
                              </span>
                              <input
                                className="form-control phone-group-0"
                                id="Applicants_0__Phone"
                                maxlength="30"
                                name="Applicants[0].Phone"
                                placeholder="(555)-555-5555"
                                type="number"
                                value={applicant.phone}
                                onChange={(e) => {
                                  setApplicants((prev) => {
                                    prev[index].phone = e.target.value;
                                    return [...prev];
                                  });
                                }}
                              />
                            </div>
                            <div className="error-msgs"></div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="CellPhone">Cell Phone</label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <MdPhoneIphone />
                              </span>
                              <input
                                className="form-control phone-group-0"
                                id="Applicants_0__CellPhone"
                                maxlength="30"
                                name="Applicants[0].CellPhone"
                                placeholder="(555)-555-5555"
                                type="number"
                                value={applicant.cellPhone}
                                onChange={(e) => {
                                  setApplicants((prev) => {
                                    prev[index].cellPhone = e.target.value;
                                    return [...prev];
                                  });
                                }}
                              />
                            </div>
                            <div className="error-msgs"></div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group required">
                            <label for="email">Email address</label>
                            <div>
                              <input
                                className="form-control"
                                id="Applicants_0__EmailAddress"
                                maxlength="80"
                                name="Applicants[0].EmailAddress"
                                placeholder="Enter email address"
                                type="email"
                                value={applicant.email}
                                onChange={(e) => {
                                  setApplicants((prev) => {
                                    prev[index].email = e.target.value;
                                    return [...prev];
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group optional">
                            <label for="inputAddress">Current Address</label>
                            <input
                              className="form-control"
                              id="Applicants_0__CurrentAddress"
                              maxlength="150"
                              name="Applicants[0].CurrentAddress"
                              placeholder="123 Main St."
                              type="text"
                              value={applicant.currentAddress1}
                              onChange={(e) => {
                                setApplicants((prev) => {
                                  prev[index].currentAddress1 = e.target.value;
                                  return [...prev];
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group optional">
                            <label for="inputAddress2">Current Address 2</label>
                            <input
                              className="form-control"
                              id="Applicants_0__CurrentAddress2"
                              maxlength="150"
                              name="Applicants[0].CurrentAddress2"
                              placeholder="Apartment, studio, or floor"
                              type="text"
                              value={applicant.currentAddress2}
                              onChange={(e) => {
                                setApplicants((prev) => {
                                  prev[index].currentAddress2 = e.target.value;
                                  return [...prev];
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="row">
                          <div className="col-md-6">
                            <label for="inputCity" className="pb-2">
                              City
                            </label>
                            <input
                              className="form-control"
                              id="Applicants_0__CurrentCity"
                              maxlength="50"
                              name="Applicants[0].CurrentCity"
                              type="text"
                              value={applicant.currentCity}
                              onChange={(e) => {
                                setApplicants((prev) => {
                                  prev[index].currentCity = e.target.value;
                                  return [...prev];
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-4">
                            <label for="inputState" className="pb-2">
                              State
                            </label>
                            <input
                              className="form-control"
                              id="Applicants_0__CurrentProvince"
                              maxlength="50"
                              name="Applicants[0].CurrentProvince"
                              type="text"
                              value={applicant.currentState}
                              onChange={(e) => {
                                setApplicants((prev) => {
                                  prev[index].currentState = e.target.value;
                                  return [...prev];
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-2">
                            <label for="inputZip" className="pb-2">
                              Postal
                            </label>
                            <input
                              className="form-control"
                              id="Applicants_0__CurrentPostal"
                              maxlength="10"
                              name="Applicants[0].CurrentPostal"
                              type="number"
                              value={applicant.currentZip}
                              onChange={(e) => {
                                setApplicants((prev) => {
                                  prev[index].currentZip = e.target.value;
                                  return [...prev];
                                });
                              }}
                            />
                          </div>
                          <div className="error-msgs"></div>
                        </div>
                        <div className="error-msgs"></div>
                      </div>

                      <div className="row" style={{ marginTop: "40px" }}>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="App2PlaceOfEmployment">
                              Place of Employment
                            </label>
                            <div>
                              <input
                                className="form-control"
                                id="Applicants_0__PlaceOfEmployment"
                                maxlength="80"
                                name="Applicants[0].PlaceOfEmployment"
                                type="text"
                                value={applicant.placeOfEmployment}
                                onChange={(e) => {
                                  setApplicants((prev) => {
                                    prev[index].placeOfEmployment =
                                      e.target.value;
                                    return [...prev];
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="App2Occupation">Occupation</label>
                            <div>
                              <input
                                className="form-control"
                                id="Applicants_0__Occupation"
                                maxlength="80"
                                name="Applicants[0].Occupation"
                                type="text"
                                value={applicant.occupation}
                                onChange={(e) => {
                                  setApplicants((prev) => {
                                    prev[index].occupation = e.target.value;
                                    return [...prev];
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label for="email">Length of employment</label>
                            <div className="row">
                              <div className="col-6 error-helper">
                                <div className="form-inline family-controls">
                                  <input
                                    className="form-control employment-length-group"
                                    id="Applicants_0__EmploymentLengthYears"
                                    maxlength="2"
                                    name="Applicants[0].EmploymentLengthYears"
                                    placeholder="YY"
                                    type="number"
                                    value={applicant.employmentLengthYears}
                                    onChange={(e) => {
                                      setApplicants((prev) => {
                                        prev[index].employmentLengthYears =
                                          e.target.value;
                                        return [...prev];
                                      });
                                    }}
                                  />
                                  <label for="email">&nbsp;&nbsp;Years</label>
                                  <div className="error-msgs"></div>
                                </div>
                              </div>
                              <div className="col-6 error-helper">
                                <div className="form-inline family-controls">
                                  <input
                                    className="form-control employment-length-group"
                                    id="Applicants_0__EmploymentLengthMonths"
                                    maxlength="2"
                                    name="Applicants[0].EmploymentLengthMonths"
                                    placeholder="MM"
                                    type="number"
                                    value={applicant.employmentLengthMonths}
                                    onChange={(e) => {
                                      setApplicants((prev) => {
                                        prev[index].employmentLengthMonths =
                                          e.target.value;
                                        return [...prev];
                                      });
                                    }}
                                  />
                                  <label for="email">&nbsp;&nbsp;Months</label>
                                  <div className="error-msgs"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group optional">
                            <label for="App2EmployerPhone">
                              Employer's Telephone
                            </label>
                            <div>
                              <input
                                className="form-control"
                                id="Applicants_0__EmployerPhone"
                                maxlength="80"
                                name="Applicants[0].EmployerPhone"
                                type="number"
                                value={applicant.employersTelephone}
                                onChange={(e) => {
                                  setApplicants((prev) => {
                                    prev[index].employersTelephone =
                                      e.target.value;
                                    return [...prev];
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="form-group optional">
                            <label for="App2HourlyWage">Hourly Wage</label>
                            <div className="input-group">
                              <span className="input-group-text">$</span>
                              <input
                                autocomplete="off"
                                className="form-control"
                                id="Applicants_0__HourlyWage"
                                maxlength="10"
                                name="Applicants[0].HourlyWage"
                                placeholder="0.00"
                                type="number"
                                value={applicant.hourlyWage}
                                onChange={(e) => {
                                  setApplicants((prev) => {
                                    prev[index].hourlyWage = e.target.value;
                                    return [...prev];
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label for="App2GrossWage">Gross Yearly</label>
                            <div className="input-group">
                              <span className="input-group-text">$</span>
                              <input
                                autocomplete="off"
                                className="form-control"
                                id="Applicants_0__GrossWage"
                                maxlength="10"
                                name="Applicants[0].GrossWage"
                                placeholder="0.00"
                                type="number"
                                value={applicant.yearlyWage}
                                onChange={(e) => {
                                  setApplicants((prev) => {
                                    prev[index].yearlyWage = e.target.value;
                                    return [...prev];
                                  });
                                }}
                              />
                            </div>
                            <div className="error-msgs"></div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group optional">
                            <label for="App2SIN">S.I.N. No.</label>
                            <input
                              autocomplete="off"
                              className="form-control"
                              id="Applicants_0__SIN"
                              maxlength="15"
                              name="Applicants[0].SIN"
                              type="number"
                              value={applicant.sinNumber}
                              onChange={(e) => {
                                setApplicants((prev) => {
                                  prev[index].sinNumber = e.target.value;
                                  return [...prev];
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-3">
                          <div className="form-group">
                            <label for="">
                              Number of vehicles owned by this applicant?
                            </label>
                            <select
                              id="applicant_0_vehicles"
                              className="form-control form-select applicant-vehicles"
                              data-index="0"
                              required
                              value={applicant.numberOfVehicles}
                              onChange={(e) => {
                                setApplicants((prev) => {
                                  prev[index].numberOfVehicles = e.target.value;
                                  return [...prev];
                                });
                              }}
                            >
                              <option value=""></option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div id="applicant-vehicle-inputs_0"></div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Have you ever filed Bankruptcy?</label>
                            <div className="form-inline">
                              <label className="radio-inline">
                                <input
                                  id="Applicants_0__Bankruptcy"
                                  name="Applicants[0].Bankruptcy"
                                  type="radio"
                                  value={applicant.bancruptcy}
                                  onChange={(e) => {
                                    setApplicants((prev) => {
                                      prev[index].bancruptcy = "yes";
                                      return [...prev];
                                    });
                                  }}
                                />{" "}
                                Yes
                              </label>
                              &nbsp; &nbsp;
                              <label className="radio-inline">
                                <input
                                  id="Applicants_0__Bankruptcy"
                                  name="Applicants[0].Bankruptcy"
                                  type="radio"
                                  value={applicant.bancruptcy}
                                  onChange={(e) => {
                                    setApplicants((prev) => {
                                      prev[index].bancruptcy = "no";
                                      return [...prev];
                                    });
                                  }}
                                />{" "}
                                No
                              </label>
                              <div className="error-msgs"></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              Have you ever done a Consumer Debt Proposal?
                            </label>
                            <div className="form-inline">
                              <label className="radio-inline">
                                <input
                                  id="Applicants_0__DebtProposal"
                                  name="Applicants[0].DebtProposal"
                                  type="radio"
                                  value={applicant.debtPurposal}
                                  onChange={(e) => {
                                    setApplicants((prev) => {
                                      prev[index].debtPurposal = "yes";
                                      return [...prev];
                                    });
                                  }}
                                />{" "}
                                Yes
                              </label>
                              &nbsp; &nbsp;
                              <label className="radio-inline">
                                <input
                                  id="Applicants_0__DebtProposal"
                                  name="Applicants[0].DebtProposal"
                                  type="radio"
                                  value={applicant.debtPurposal}
                                  onChange={(e) => {
                                    setApplicants((prev) => {
                                      prev[index].debtPurposal = "no";
                                      return [...prev];
                                    });
                                  }}
                                />{" "}
                                No
                              </label>
                              <div className="error-msgs"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Do you pay your bills on time?</label>
                            <div className="form-inline">
                              <label className="radio-inline">
                                <input
                                  id="Applicants_0__BillsOnTime"
                                  name="Applicants[0].BillsOnTime"
                                  type="radio"
                                  value={applicant.payOnTime}
                                  onChange={(e) => {
                                    setApplicants((prev) => {
                                      prev[index].payOnTime = "yes";
                                      return [...prev];
                                    });
                                  }}
                                />{" "}
                                Yes
                              </label>
                              &nbsp; &nbsp;
                              <label className="radio-inline">
                                <input
                                  id="Applicants_0__BillsOnTime"
                                  name="Applicants[0].BillsOnTime"
                                  type="radio"
                                  value={applicant.payOnTime}
                                  onChange={(e) => {
                                    setApplicants((prev) => {
                                      prev[index].payOnTime = "no";
                                      return [...prev];
                                    });
                                  }}
                                />{" "}
                                No
                              </label>
                              <div className="error-msgs"></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              Has anyone ever come after you for money?
                            </label>
                            <div className="form-inline">
                              <label className="radio-inline">
                                <input
                                  id="Applicants_0__AfterMoney"
                                  name="Applicants[0].AfterMoney"
                                  type="radio"
                                  value={applicant.afterForMoney}
                                  onChange={(e) => {
                                    setApplicants((prev) => {
                                      prev[index].afterForMoney = "yes";
                                      return [...prev];
                                    });
                                  }}
                                />{" "}
                                Yes
                              </label>
                              &nbsp; &nbsp;
                              <label className="radio-inline">
                                <input
                                  id="Applicants_0__AfterMoney"
                                  name="Applicants[0].AfterMoney"
                                  type="radio"
                                  value={applicant.afterForMoney}
                                  onChange={(e) => {
                                    setApplicants((prev) => {
                                      prev[index].afterForMoney = "no";
                                      return [...prev];
                                    });
                                  }}
                                />{" "}
                                No
                              </label>
                              <div className="error-msgs"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <label>
                            Photo ID, Proof of Income, and Credit Report (At
                            least 3 documents required up to 5 max. We accept
                            images and PDF's).{" "}
                          </label>
                          <div
                            style={{ paddingTop: "5px", paddingBottom: "5px" }}
                          >
                            <b
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5vmax",
                              }}
                            >
                              <a
                                href="#credit-links"
                                style={{
                                  color: "#337ab7",
                                  textDecoration: "none",
                                  fontSize: "14px",
                                }}
                              >
                                You can submit your own credit report by using
                                either of the links above.
                              </a>
                              <FaArrowAltCircleUp style={{ color: "green" }} />
                            </b>
                          </div>
                          <div
                            className="form-group"
                            style={{
                              paddingBottom: "0px",
                              marginBottom: "0px",
                            }}
                          >
                            <div style={{ height: "0px", overflow: "hidden" }}>
                              <input
                                type="text"
                                id="applicant-0-document-count"
                                name="applicant-0-document-count"
                                value="0"
                                className="document-count"
                              />
                            </div>
                            <FilePond
                              files={applicant.docsPreview}
                              allowReorder={true}
                              allowMultiple={true}
                              onupdatefiles={async (fileItems) => {
                                try {
                                  const documentPromises = fileItems.map(
                                    async (fileItem) => {
                                      const formData = new FormData();
                                      formData.append("photos", fileItem.file);

                                      const res = await axios.post(
                                        `${url}/api/property/uploadmedia`,
                                        formData,
                                        {
                                          headers: {
                                            "Content-Type":
                                              "multipart/form-data",
                                          },
                                        }
                                      );

                                      return res.data[0];
                                    }
                                  );

                                  // Wait for all promises to resolve
                                  const uploadedDocuments = await Promise.all(
                                    documentPromises
                                  );

                                  setApplicants((prev) => {
                                    const updatedApplicants = [...prev];
                                    updatedApplicants[index].documents =
                                      uploadedDocuments;
                                    return updatedApplicants;
                                  });

                                  setApplicants((prev) => {
                                    const updatedApplicants = [...prev];
                                    updatedApplicants[index].docsPreview =
                                      fileItems;
                                    return updatedApplicants;
                                  });
                                } catch (err) {
                                  console.error("Error uploading files:", err);
                                }
                              }}
                              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                              acceptedFileTypes={["image/*", "application/pdf"]}
                              maxFiles={5}
                              minFiles={3}
                            />

                            <div className="error-msgs"></div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </>
                ))}
              </div>

              <div className="section-header">
                <h3>Co-Signer</h3>
              </div>

              <fieldset className="section">
                <div className="row">
                  <div className="col-sm-12">
                    <label>Will there be a co-signer?</label>
                    <div
                      style={{
                        display: "flex",
                        gap: "1vmax",
                        alignItems: "center",
                      }}
                    >
                      <label className="radio-inline">
                        <input
                          type="radio"
                          id="has-co-signer-yes"
                          name="HasCoSigner"
                          value="yes"
                          onChange={(e) => setIsCoSingned(e.target.value)}
                        />{" "}
                        Yes
                      </label>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          id="has-co-signer-no"
                          name="HasCoSigner"
                          value="no"
                          onChange={(e) => setIsCoSingned(e.target.value)}
                        />{" "}
                        No
                      </label>
                      <div className="error-msgs"></div>
                    </div>
                  </div>
                </div>
                {isCoSingned == "yes" && (
                  <>
                    <div className="row ">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label for="CoSigner_FirstName">Name</label>
                          <div className="row row-no-padding-sm">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                id="CoSigner_FirstName"
                                maxlength="25"
                                name="CoSigner.FirstName"
                                placeholder="First"
                                type="text"
                                value={coSignnerDetails?.firstName}
                                onChange={(e) =>
                                  setCoSignnerDetails({
                                    ...coSignnerDetails,
                                    firstName: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                id="CoSigner_LastName"
                                maxlength="25"
                                name="CoSigner.LastName"
                                placeholder="Last"
                                type="text"
                                value={coSignnerDetails?.lastName}
                                onChange={(e) =>
                                  setCoSignnerDetails({
                                    ...coSignnerDetails,
                                    lastName: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="error-msgs"></div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label for="CoSigner_BirthYear">Date of Birth</label>
                          <div className="row row-no-padding">
                            <div className="col-4">
                              <select
                                className="form-control form-select"
                                id="CoSigner_BirthYear"
                                name="CoSigner.BirthYear"
                                value={coSignnerDetails?.birthYear}
                                onChange={(e) => {
                                  setCoSignnerDetails({
                                    ...coSignnerDetails,
                                    birthYear: e.target.value,
                                  });
                                }}
                              >
                                <option>YY</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                                <option value="2019">2019</option>
                                <option value="2018">2018</option>
                                <option value="2017">2017</option>
                                <option value="2016">2016</option>
                                <option value="2015">2015</option>
                                <option value="2014">2014</option>
                                <option value="2013">2013</option>
                                <option value="2012">2012</option>
                                <option value="2011">2011</option>
                                <option value="2010">2010</option>
                                <option value="2009">2009</option>
                                <option value="2008">2008</option>
                                <option value="2007">2007</option>
                                <option value="2006">2006</option>
                                <option value="2005">2005</option>
                                <option value="2004">2004</option>
                                <option value="2003">2003</option>
                                <option value="2002">2002</option>
                                <option value="2001">2001</option>
                                <option value="2000">2000</option>
                                <option value="1999">1999</option>
                                <option value="1998">1998</option>
                                <option value="1997">1997</option>
                                <option value="1996">1996</option>
                                <option value="1995">1995</option>
                                <option value="1994">1994</option>
                                <option value="1993">1993</option>
                                <option value="1992">1992</option>
                                <option value="1991">1991</option>
                                <option value="1990">1990</option>
                                <option value="1989">1989</option>
                                <option value="1988">1988</option>
                                <option value="1987">1987</option>
                                <option value="1986">1986</option>
                                <option value="1985">1985</option>
                                <option value="1984">1984</option>
                                <option value="1983">1983</option>
                                <option value="1982">1982</option>
                                <option value="1981">1981</option>
                                <option value="1980">1980</option>
                                <option value="1979">1979</option>
                                <option value="1978">1978</option>
                                <option value="1977">1977</option>
                                <option value="1976">1976</option>
                                <option value="1975">1975</option>
                                <option value="1974">1974</option>
                                <option value="1973">1973</option>
                                <option value="1972">1972</option>
                                <option value="1971">1971</option>
                                <option value="1970">1970</option>
                                <option value="1969">1969</option>
                                <option value="1968">1968</option>
                                <option value="1967">1967</option>
                                <option value="1966">1966</option>
                                <option value="1965">1965</option>
                                <option value="1964">1964</option>
                                <option value="1963">1963</option>
                                <option value="1962">1962</option>
                                <option value="1961">1961</option>
                                <option value="1960">1960</option>
                                <option value="1959">1959</option>
                                <option value="1958">1958</option>
                                <option value="1957">1957</option>
                                <option value="1956">1956</option>
                                <option value="1955">1955</option>
                                <option value="1954">1954</option>
                                <option value="1953">1953</option>
                                <option value="1952">1952</option>
                                <option value="1951">1951</option>
                                <option value="1950">1950</option>
                                <option value="1949">1949</option>
                                <option value="1948">1948</option>
                                <option value="1947">1947</option>
                                <option value="1946">1946</option>
                                <option value="1945">1945</option>
                                <option value="1944">1944</option>
                                <option value="1943">1943</option>
                                <option value="1942">1942</option>
                                <option value="1941">1941</option>
                                <option value="1940">1940</option>
                                <option value="1939">1939</option>
                                <option value="1938">1938</option>
                                <option value="1937">1937</option>
                                <option value="1936">1936</option>
                                <option value="1935">1935</option>
                                <option value="1934">1934</option>
                                <option value="1933">1933</option>
                                <option value="1932">1932</option>
                                <option value="1931">1931</option>
                                <option value="1930">1930</option>
                                <option value="1929">1929</option>
                                <option value="1928">1928</option>
                                <option value="1927">1927</option>
                                <option value="1926">1926</option>
                                <option value="1925">1925</option>
                                <option value="1924">1924</option>
                                <option value="1923">1923</option>
                                <option value="1922">1922</option>
                                <option value="1921">1921</option>
                                <option value="1920">1920</option>
                                <option value="1919">1919</option>
                                <option value="1918">1918</option>
                                <option value="1917">1917</option>
                                <option value="1916">1916</option>
                                <option value="1915">1915</option>
                                <option value="1914">1914</option>
                                <option value="1913">1913</option>
                                <option value="1912">1912</option>
                                <option value="1911">1911</option>
                                <option value="1910">1910</option>
                                <option value="1909">1909</option>
                                <option value="1908">1908</option>
                                <option value="1907">1907</option>
                                <option value="1906">1906</option>
                                <option value="1905">1905</option>
                                <option value="1904">1904</option>
                              </select>
                            </div>
                            <div className="col-4">
                              <select
                                className="form-control form-select"
                                id="CoSigner_BirthMonth"
                                name="CoSigner.BirthMonth"
                                value={coSignnerDetails?.birthMonth}
                                onChange={(e) =>
                                  setCoSignnerDetails({
                                    ...coSignnerDetails,
                                    birthMonth: e.target.value,
                                  })
                                }
                              >
                                <option>MM</option>
                                <option value="Jan">Jan</option>
                                <option value="Feb">Feb</option>
                                <option value="Mar">Mar</option>
                                <option value="Apr">Apr</option>
                                <option value="May">May</option>
                                <option value="Jun">Jun</option>
                                <option value="Jul">Jul</option>
                                <option value="Aug">Aug</option>
                                <option value="Sep">Sep</option>
                                <option value="Oct">Oct</option>
                                <option value="Nov">Nov</option>
                                <option value="Dec">Dec</option>
                                <option value="12"></option>
                              </select>
                            </div>
                            <div className="col-4">
                              <select
                                className="form-control form-select"
                                id="CoSigner_BirthDay"
                                name="CoSigner.BirthDay"
                                value={coSignnerDetails?.birthDay}
                                onChange={(e) =>
                                  setCoSignnerDetails({
                                    ...coSignnerDetails,
                                    birthDay: e.target.value,
                                  })
                                }
                              >
                                <option>DD</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                              </select>
                            </div>
                          </div>
                          <div className="error-msgs"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label for="CoSigner_Phone">Home Phone</label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <IoIosCall />
                            </span>
                            <input
                              className="form-control cosigner-phone-group"
                              id="CoSigner_Phone"
                              maxlength="30"
                              name="CoSigner.Phone"
                              placeholder="(555)-555-5555"
                              type="number"
                              value={coSignnerDetails?.homePhone}
                              onChange={(e) =>
                                setCoSignnerDetails({
                                  ...coSignnerDetails,
                                  homePhone: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="error-msgs"></div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label for="CoSigner_CellPhone">Cell Phone</label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <MdPhoneIphone />
                            </span>
                            <input
                              className="form-control "
                              id="CoSigner_CellPhone"
                              maxlength="30"
                              name="CoSigner.CellPhone"
                              placeholder="(555)-555-5555"
                              type="number"
                              value={coSignnerDetails?.cellPhone}
                              onChange={(e) =>
                                setCoSignnerDetails({
                                  ...coSignnerDetails,
                                  cellPhone: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="error-msgs"></div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group required">
                          <label for="CoSigner_EmailAddress">
                            Email address
                          </label>
                          <div>
                            <input
                              className="form-control"
                              id="CoSigner_EmailAddress"
                              maxlength="80"
                              name="CoSigner.EmailAddress"
                              placeholder="Enter email address"
                              type="email"
                              value={coSignnerDetails?.email}
                              onChange={(e) =>
                                setCoSignnerDetails({
                                  ...coSignnerDetails,
                                  email: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "40px" }}>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label for="CoSigner_PlaceOfEmployment">
                            Place of Employment
                          </label>
                          <div>
                            <input
                              className="form-control"
                              id="CoSigner_PlaceOfEmployment"
                              maxlength="80"
                              name="CoSigner.PlaceOfEmployment"
                              type="text"
                              value={coSignnerDetails?.placeOfEmployment}
                              onChange={(e) =>
                                setCoSignnerDetails({
                                  ...coSignnerDetails,
                                  placeOfEmployment: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label for="CoSigner_Occupation">Occupation</label>
                          <div>
                            <input
                              className="form-control"
                              id="CoSigner_Occupation"
                              maxlength="80"
                              name="CoSigner.Occupation"
                              type="text"
                              value={coSignnerDetails?.occupation}
                              onChange={(e) =>
                                setCoSignnerDetails({
                                  ...coSignnerDetails,
                                  occupation: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label for="CoSigner_EmploymentLengthYears">
                            Length of employment
                          </label>
                          <div className="row">
                            <div className="col-6 error-helper">
                              <div className="form-inline family-controls">
                                <input
                                  className="form-control cosigner-employment-length-group"
                                  id="CoSigner_EmploymentLengthYears"
                                  maxlength="2"
                                  name="CoSigner.EmploymentLengthYears"
                                  placeholder="YY"
                                  type="number"
                                  value={
                                    coSignnerDetails?.lengthofEmploymentYears
                                  }
                                  onChange={(e) =>
                                    setCoSignnerDetails({
                                      ...coSignnerDetails,
                                      lengthofEmploymentYears: e.target.value,
                                    })
                                  }
                                />
                                &nbsp;&nbsp;
                                <label for="CoSigner_EmploymentLengthYears">
                                  Years
                                </label>
                                <div className="error-msgs"></div>
                              </div>
                            </div>
                            <div className="col-6 error-helper">
                              <div className="form-inline family-controls">
                                <input
                                  className="form-control cosigner-employment-length-group"
                                  id="CoSigner_EmploymentLengthMonths"
                                  maxlength="2"
                                  name="CoSigner.EmploymentLengthMonths"
                                  placeholder="MM"
                                  type="number"
                                  value={
                                    coSignnerDetails?.lengthofEmploymentMonths
                                  }
                                  onChange={(e) =>
                                    setCoSignnerDetails({
                                      ...coSignnerDetails,
                                      lengthofEmploymentMonths: e.target.value,
                                    })
                                  }
                                />
                                &nbsp;&nbsp;
                                <label for="CoSigner_EmploymentLengthMonths">
                                  Months
                                </label>
                                <div className="error-msgs"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group optional">
                          <label for="App2EmployerPhone">
                            Employer's Telephone
                          </label>
                          <div>
                            <input
                              className="form-control"
                              id="CoSigner_EmployerPhone"
                              maxlength="80"
                              name="CoSigner.EmployerPhone"
                              type="number"
                              value={coSignnerDetails?.employersTelephone}
                              onChange={(e) =>
                                setCoSignnerDetails({
                                  ...coSignnerDetails,
                                  employersTelephone: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-sm-3">
                        <div className="form-group optional">
                          <label for="App2HourlyWage">Hourly Wage</label>
                          <div className="input-group">
                            <span className="input-group-text">$</span>
                            <input
                              autocomplete="off"
                              className="form-control"
                              id="CoSigner_HourlyWage"
                              maxlength="10"
                              name="CoSigner.HourlyWage"
                              placeholder="0.00"
                              type="number"
                              value={coSignnerDetails?.hourlyWage}
                              onChange={(e) =>
                                setCoSignnerDetails({
                                  ...coSignnerDetails,
                                  hourlyWage: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label for="App2GrossWage">Gross Yearly</label>
                          <div className="input-group">
                            <span className="input-group-text">$</span>
                            <input
                              autocomplete="off"
                              className="form-control"
                              id="CoSigner_GrossWage"
                              maxlength="10"
                              name="CoSigner.GrossWage"
                              placeholder="0.00"
                              type="number"
                              value={coSignnerDetails?.yearlyWage}
                              onChange={(e) =>
                                setCoSignnerDetails({
                                  ...coSignnerDetails,
                                  yearlyWage: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="error-msgs"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-xs-12">
                        <div className="form-group">
                          <label for="ViewingDetails">
                            On who's behalf will you be co-signing?:
                          </label>
                          <textarea
                            cols="20"
                            id="CoSigner_SigningFor"
                            name="CoSigner.SigningFor"
                            placeholder="Please list all names..."
                            rows="3"
                            style={{ width: "100%" }}
                            value={coSignnerDetails?.behalfOfApplicant}
                            onChange={(e) =>
                              setCoSignnerDetails({
                                ...coSignnerDetails,
                                behalfOfApplicant: e.target.value,
                              })
                            }
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          Photo ID, Proof of Income, and Credit Report (At least
                          3 documents required up to 5 max. We accept images and
                          PDF's).{" "}
                        </label>
                        <div
                          style={{ paddingTop: "5px", paddingBottom: "5px" }}
                        >
                          <b
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.5vmax",
                            }}
                          >
                            <a
                              href="#credit-links"
                              style={{
                                color: "#337ab7",
                                textDecoration: "none",
                                fontSize: "14px",
                              }}
                            >
                              You can submit your own credit report by using
                              either of the links above.
                            </a>
                            <FaArrowAltCircleUp style={{ color: "green" }} />
                          </b>
                        </div>
                        <div
                          className="form-group"
                          style={{ marginBottom: "0px" }}
                        >
                          <FilePond
                            files={coSignnerDetails?.docsPreview}
                            allowReorder={true}
                            allowMultiple={true}
                            onupdatefiles={async (pre) => {
                              try {
                                const documentPromises = pre.map(
                                  async (fileItem) => {
                                    const formData = new FormData();
                                    formData.append("photos", fileItem.file);

                                    const res = await axios.post(
                                      `${url}/api/property/uploadmedia`,
                                      formData,
                                      {
                                        headers: {
                                          "Content-Type": "multipart/form-data",
                                        },
                                      }
                                    );

                                    return res.data[0];
                                  }
                                );

                                // Wait for all promises to resolve
                                const uploadedDocuments = await Promise.all(
                                  documentPromises
                                );

                                setCoSignnerDetails((prev) => ({
                                  ...prev,
                                  documents: uploadedDocuments,
                                }));

                                setCoSignnerDetails((prev) => ({
                                  ...prev,
                                  docsPreview: pre.map(
                                    (fileItem) => fileItem.file
                                  ),
                                }));
                              } catch (err) {
                                console.error("Error uploading files:", err);
                              }
                            }}
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                            acceptedFileTypes={["image/*", "application/pdf"]}
                            maxFiles={5}
                            minFiles={3}
                          />
                          <div className="error-msgs"></div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </fieldset>

              <div className="section-header">
                <h3>Other</h3>
              </div>
              <fieldset className="section">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label for="Expectations">Additional Expectations</label>
                      <div style={{ marginBottom: "10px" }}>
                        Please note, all units are rented 'as is'. Any
                        alteration requests must be put in writing during the
                        application process and will be submitted to the owner
                        for approval. List any and all additional expectations
                        that you feel need to be addressed prior to moving in.
                        We will do our best to accommodate you, but we do not
                        make any guarantees, whatsoever. Thank-you, The
                        Management.
                      </div>

                      <textarea
                        cols="20"
                        id="Expectations"
                        name="Expectations"
                        rows="3"
                        style={{ width: "100%" }}
                        value={additionalExpectations}
                        onChange={(e) =>
                          setAdditionalExpectations(e.target.value)
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div id="terms-modal" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  {/* Modal content */}
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        &times;
                      </button>
                      <h4 className="modal-title">Terms &amp; Conditions</h4>
                    </div>
                    <div className="modal-body">
                      <div id="app-terms">
                        <h2>
                          You must agree to the following terms and conditions
                          before submitting your application. Please read
                          carefully!
                        </h2>
                        <p>
                          <b>1)</b> Upon approval of this rental application by
                          the landlord, a binding agreement shall be created
                          between the parties who shall immediately enter into a
                          tenancy agreement on these same terms on the
                          landlord’s standard form. Upon such approval, the last
                          month’s rent deposit shall be applied as security
                          towards the payment of the last month’s rent. However
                          If this rental application is not approved, any rent
                          deposit shall be returned forthwith less a
                          non-refundable application administration fee of $
                          0.00. It is agreed that once this application has been
                          approved, the last month’s rent deposit may be held by
                          the landlord as security against rent whether or not a
                          signed tenancy agreement is entered into or whether or
                          not the tenant moves into the rental unit, and that
                          the deposit may be applied as rent provided that the
                          rental unit has not been re-rented prior to the
                          anticipated commencement date as above.
                          <div className="form-group">
                            <div className="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  id="terms-01"
                                  name="terms-01"
                                />{" "}
                                <b>I agree to the terms in clause 1 above</b>
                              </label>
                            </div>
                            <div className="error-msgs"></div>
                          </div>
                        </p>
                        <p>
                          <b>2)</b> The applicant consents to credit and
                          background check of information and to the disclosure
                          of any information, to any person with whom the
                          undersigned has or proposes to have financial
                          relations. The applicant gives their consent to K-W
                          Property Management Corp and or its agents to review
                          my personal credit file in conjunction with this
                          application. The applicant further authorizes K-W
                          Property Management Corp and its agents to exchange
                          your personal information on an ongoing basis with
                          credit bureaus and permit such organizations to verify
                          your personal information in order to protect you,
                          ensure the completeness of the information and
                          maintain the integrity of the credit granting system,
                          and to co-operate with local, provincial and national
                          authorities in the investigation of unlawful or
                          improper activities in order to protect you the
                          applicant and K-W Property Management Corp from
                          fraudulent transactions. You, the applicant also
                          authorize K-W Property Management Corp and its agents
                          to exchange your personal information for collection
                          or legal matters.
                        </p>
                        <p>
                          <b>3)</b> Upon execution of the tenancy agreement, the
                          tenant(s) shall be required to pay the rent for the
                          first month of the tenancy prior to the commencement
                          date of the tenancy. The tenant’s right to occupy the
                          rental unit does not commence until such time as all
                          required payments have been made and until the tenant
                          has transferred responsibility for utilities to
                          his/her own name if required as above as well as proof
                          of tenant insurance.
                        </p>
                        <p>
                          <b>4)</b> If for any reason, the landlord is unable to
                          give possession of the rental premises on the
                          commencement date of the lease term, the landlord
                          shall not be subject to any liability to the
                          applicants and shall give possession to them as soon
                          as the landlord is able to do so with the rent abated
                          until such time, and this will not affect the validity
                          of the tenancy agreement, the obligations of the
                          parties, nor shall it be construed as extending the
                          term of the agreement.
                        </p>
                        <p>
                          <b>5)</b> I hereby certify that the above information
                          is true and complete and that I have not withheld any
                          information relevant to this application. It is also
                          understood that the property management and/or owner
                          reserve the right to reject this application at their
                          sole discretion subject to the full return of any rent
                          deposit paid less a non-refundable application
                          administration fee of $ $0.00.
                        </p>
                        <p>
                          <b>
                            By clicking "I Agree" your are indicating that you
                            have read and understand these conditions.
                          </b>
                        </p>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        id="agree-button"
                        name="agree-button"
                        type="submit"
                        className="btn btn-primary"
                      >
                        I Agree
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "20px" }}>
                <button
                  type="submit"
                  id="submit-button"
                  name="submit-button"
                  className="btn btn-lg btn-primary"
                  style={{ backgroundColor: "rgb(51,122,183)" }}
                >
                  Submit Application
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationPage;
