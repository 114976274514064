import React from "react";
import FlexRow from "../../Layouts/FlexRow";

const DownloadPDF = (props) => {
  const { descriptionL1, descriptionL2, fileName, style } = props;
  const downloadPDF = (fileName) => {
    fetch(fileName).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
      });
    });
  };
  return (
    <div
      className="input-box"
      onClick={() => downloadPDF(fileName)}
      style={style}
    >
      <FlexRow style={{ width: "100%" }}>
        <div className="icon-text">
          <p className="icon-container">
            <span className="icon-download"></span>
          </p>
          <p>
            {descriptionL1}
            <br />
            {descriptionL2}
          </p>
        </div>
      </FlexRow>
    </div>
  );
};

export default DownloadPDF;
