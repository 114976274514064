import React from "react";
import "./InputText.scss";

const InputText = (props) => {
  const {
    id,
    type,
    className,
    name,
    readOnly,
    noClass,
    value,
    placeholder,
    disabled,
    onChange,
    register,
    areaInvalid,
    maxLength,
  } = props;
  return (
    <div className="input_default">
      <input
        {...register}
        id={id ? id : ""}
        type={type ? type : "text"}
        className={noClass ? "" : className ? className : ""}
        value={value ? value : undefined}
        placeholder={placeholder ? placeholder : "Input"}
        disabled={disabled ? true : false}
        onChange={onChange ? onChange : undefined}
        readOnly={readOnly ? "readonly" : ""}
        aria-invalid={areaInvalid ? areaInvalid : ""}
        maxLength={maxLength ? maxLength : ""}
      />
    </div>
  );
};

export default InputText;
