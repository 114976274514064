// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-text {
  font-family: "Graphik-Regular";
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #373737;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 576px) {
  .default-text {
    letter-spacing: 0.1px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Elements/Typography/CustomHeader/CustomHeader.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,kBAAA;EACA,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,iBAAA;EACA,sBAAA;EACA,cAAA;EACA,mBAAA;AACF;AAAE;EAVF;IAWI,qBAAA;EAGF;AACF","sourcesContent":[".default-text {\n  font-family: \"Graphik-Regular\";\n  font-size: 0.75rem;\n  font-weight: normal;\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.67;\n  letter-spacing: normal;\n  color: #373737;\n  margin-bottom: 1rem;\n  @media only screen and (max-width: 576px) {\n    letter-spacing: 0.1px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
