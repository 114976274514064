import React from "react";
import "./AboutSteps.scss";
import { SquareBox } from "../../Layouts";
import tenPuls from '../../../assets/Industry-experience.png';

const AboutSteps = (props) => {
  const { className, style, children } = props;
  return (
    <div className={`frently-about-steps ${className}`} style={style}>
      <SquareBox
        url={tenPuls}
        desc="Years of Industry Experience"
      />
      <SquareBox
        url="https://frentlyassets.s3.ca-central-1.amazonaws.com/homepage/Flexible-Terms.png"
        desc="Short and long term"
      />
      <SquareBox
        url="https://frentlyassets.s3.ca-central-1.amazonaws.com/homepage/verified-listings.png"
        desc="Verified listings"
      />
      <SquareBox
        url="https://frentlyassets.s3.ca-central-1.amazonaws.com/homepage/Easy.png"
        desc="Easy as 1, 2, 3"
      />
    </div>
  );
};

export default AboutSteps;
