export const routePaths = {
  root: "/",
  landingPage: "/listings",
  loginPage: "/login",
  listingManagementPage: "/listing-management",
  addPropertyPage: "/add-property",
  applicationPage: "/application/:id",
  listingdetails: "/listing/:id",
  editproperty: "/edit-property/:id"
};
