// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-img {
  width: 100%;
  height: 453px;
}
@media only screen and (max-width: 576px) {
  .hero-img {
    width: 100px;
    height: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Elements/Image/ImageSrcset.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;AACF;AAAE;EAHF;IAII,YAAA;IACA,aAAA;EAGF;AACF","sourcesContent":[".hero-img {\n  width: 100%;\n  height: 453px;\n  @media only screen and (max-width: 576px) {\n    width: 100px;\n    height: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
