import React, { useState } from "react";
import "./FAQ.scss";
import data from "./faq.json";
import { Accordian } from "../../Elements";

const FAQs = (props) => {
  const { className, style, children } = props;
  const [faqs, setFaqs] = useState(data);
  const faqLength = data.length - 1;

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      })
    );
  };
  return (
    <div className="frently-accordian-container">
      <div className="frently-accordian-desc">
        Got questions? <br />
        We got answers.
      </div>
      <div className={`FAQ-container ${className}`}>
        <div className="faqs">
          {faqs.map((faq, index) => (
            <Accordian
              faq={faq}
              index={index}
              key={index}
              toggleFAQ={toggleFAQ}
              indexNum={index}
              faqLength={faqLength}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
