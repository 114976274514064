// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mega-title {
  font-family: "Graphik-Medium";
  font-size: 2.938rem;
  line-height: 1.02;
  letter-spacing: -0.7px;
  color: #373737;
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Elements/Typography/MegaTitle/MegaTitle.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,mBAAA;EACA,iBAAA;EACA,sBAAA;EACA,cAAA;EACA,mBAAA;AACJ","sourcesContent":[".mega-title{\n    font-family: \"Graphik-Medium\"; \n    font-size: 2.938rem;\n    line-height: 1.02;\n    letter-spacing: -0.7px;\n    color: #373737;\n    margin-bottom:1rem;\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
