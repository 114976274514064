// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-text {
  font-size: 1.375rem;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  color: #373737;
}
@media only screen and (max-width: 576px) {
  .primary-text {
    font-size: 1rem;
  }
}

.text-medium {
  font-family: "Graphik-Medium";
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/Elements/Typography/PrimaryHeader/PrimaryHeader.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,oBAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAAI;EANJ;IAOQ,eAAA;EAGN;AACF;;AADA;EACI,6BAAA;EACA,gBAAA;AAIJ","sourcesContent":[".primary-text {\n    font-size: 1.375rem;\n    font-stretch: normal;\n    font-style: normal;\n    line-height: 1.45;\n    color: #373737;\n    @media only screen and (max-width: 576px) {\n        font-size: 1rem;\n    }\n}\n.text-medium {\n    font-family: \"Graphik-Medium\";\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
