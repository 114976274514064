import React,{useEffect} from 'react';
import "./listingManagement.scss";
import Navbar from '../NavBar/Index';
import ListingsPage from '../ListingsPage/Index';
import { useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import ScrollTop from '../../hooks/ScrollTop';


function ListingManagementPage() {
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = React.useState('listing');

    useEffect(() => {
        if (!sessionStorage.getItem('token')) {
            navigate('/');
        }
    }, [])

    return (
        <>
            <ScrollTop />
            <Navbar />
            <div className='main-wrapper'>
                <div className='slidebar-wrapper'>
                    <div className='slidebar'>
                        <ul className='slidebar-list'>
                            <li className={selectedTab === 'listing' ? 'active' : 'list-item'} onClick={() => setSelectedTab('listing')}>Listings</li>
                            {/* <li className={selectedTab === 'invite' ? 'active' : 'list-item'} 
                            // onClick={() => setSelectedTab('invite')}
                            >Invite Friends</li> */}
                            <li className='list-item'
                            onClick={() => {
                                googleLogout();
                                sessionStorage.removeItem('token');
                                sessionStorage.removeItem('user');
                                navigate('/')
                            }}
                            >Logout</li>
                        </ul>
                    </div>
                </div>
                <div className='container-wrapper'>
                {selectedTab === 'listing' && <ListingsPage />}
                
                </div>
            </div>
            
        </>
    );
}

export default ListingManagementPage;