import React, { useState, useEffect } from "react";
import Notification from "../Notification/Notification";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { url } from "../../constData";
import Navbar from "../NavBar/Index";
import { googleLogout } from "@react-oauth/google";
import ScrollTop from "../../hooks/ScrollTop";
import CreatableSelect from "react-select/creatable";
import { CircularProgress, Backdrop } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import Button from "@mui/material/Button";
import { MarginBottom } from "../Elements";


function EditProperty() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [title, setTitle] = useState("");
  const [propertyFor, setPropertyFor] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [price, setPrice] = useState(null);
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [squareFeet, setSquareFeet] = useState(null);
  const [laundryType, setLaundryType] = useState("");
  const [parkingType, setParkingType] = useState("");
  const [parkingSpots, setParkingSpots] = useState("0");
  const [airConditioning, setAirConditioning] = useState("");
  const [heatingType, setHeatingType] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isLeased, setIsLeased] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);
  const [imagePreview, setImagePreview] = useState([]);
  const [videoPreview, setVideoPreview] = useState([]);
  const [amenitiesEdit, setAmenitiesEdit] = useState([]);

  // console.log(photos);
  useEffect(() => {
    if (!sessionStorage.getItem("token")) {
      navigate("/");
    }
    const user = JSON.parse(sessionStorage.getItem("user"));
    setCreatedBy(user?._id);
    const fetchData = async () => {
      const res = await axios.get(`${url}/api/property/listing/${id}`);
      // console.log(res.data);
      setData(res.data);
      setTitle(res.data.title);
      setPropertyFor(res.data.propertyFor);
      setPropertyType(res.data.propertyType);
      setBedrooms(res.data.bedrooms);
      setBathrooms(res.data.bathrooms);
      setPrice(res.data.price);
      setAddress(res.data.address);
      setDescription(res.data.description);
      setSquareFeet(res.data.squareFeet);
      setLaundryType(res.data.laundryType);
      setParkingType(res.data.parkingType);
      setParkingSpots(res.data.parkingSpots);
      setAirConditioning(res.data.airConditioning);
      setHeatingType(res.data.heatingType);
      setAmenities(
        res.data.amenities.map((item) => ({ value: item, label: item }))
      );
      setPhotos(res.data.photos);
      setVideos(res.data.videos);
      setIsLeased(res.data.isLeased);
      setImagePreview(res.data.photos);
      setVideoPreview(res.data.videos);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setAmenitiesEdit(amenities.map((item) => item.value));
  }, [amenities]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    // const formData = new FormData();
    // photos.forEach((photo, index) => {
    //   formData.append(`photos`, photo);
    // });

    // formData.append("title", title);
    // formData.append("propertyFor", propertyFor);
    // formData.append("propertyType", propertyType);
    // formData.append("bedrooms", bedrooms);
    // formData.append("bathrooms", bathrooms);
    // formData.append("price", price);
    // formData.append("address", address);
    // formData.append("description", description);
    // formData.append("squareFeet", squareFeet);
    // formData.append("laundryType", laundryType);
    // formData.append("parkingType", parkingType);
    // formData.append("parkingSpots", parkingSpots);
    // formData.append("airConditioning", airConditioning);
    // formData.append("heatingType", heatingType);
    // formData.append("amenities", amenitiesEdit);
    // formData.append("photos", photos)
    // formData.append("videos", videos);
    // formData.append("isLeased", isLeased);

    try {
      if (squareFeet < 200 || squareFeet > 8000) {
        alert("Square feet must be between 200 and 8000");
        setLoading(false);
        return;
      }

      const response = await axios.post(
        `${url}/api/property/update/${id}`,
        {
          title: title,
          propertyFor: propertyFor,
          propertyType: propertyType,
          bedrooms: bedrooms,
          bathrooms: bathrooms,
          price: price,
          address: address,
          description: description,
          squareFeet: squareFeet,
          laundryType: laundryType,
          parkingType: parkingType,
          parkingSpots: parkingSpots,
          airConditioning: airConditioning,
          heatingType: heatingType,
          amenities: amenitiesEdit,
          photos: photos,
          videos: videos,
          isLeased: isLeased,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // console.log(response);
      if (response.status === 200) {
        navigate("/listing-management");
        setLoading(false);
      }
    } catch (error) {
      Notification.error(error.response.data.message);
      alert(error.response.data.message);
      console.error(error.response.data);
      setLoading(false);
    }
  };

  // console.log(photos);

  const handleDelete = async (e) => {
    const res = await axios.delete(`${url}/api/property/delete/${id}`);
    if (res.status === 200) {
      navigate("/listing-management");
    }
  };

  // console.log(amenities);

  const options = [
    "Air conditioning",
    "Balcony",
    "Dishwasher",
    "Elevator",
    "Fireplace",
    "Garage",
    "Gym",
    "Laundry",
    "Parking",
    "Pool",
    "Wheelchair accessible",
  ].map((option) => ({
    value: option,
    label: option,
  }));
  return (
    <>
      <ScrollTop />
      <Navbar />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="main-wrapper">
        <div className="slidebar-wrapper">
          <div className="slidebar">
            <ul className="slidebar-list">
              <li
                className={"list-item"}
                onClick={() => navigate("/listing-management")}
              >
                Listings
              </li>
              {/* <li className={"list-item"}>Invite Friends</li> */}
              <li
                className="list-item"
                onClick={() => {
                  googleLogout();
                  sessionStorage.removeItem("token");
                  sessionStorage.removeItem("user");
                  navigate("/");
                }}
              >
                Logout
              </li>
            </ul>
          </div>
        </div>
        <div className="container-wrapper">
          <div className="container">
            <div className="row">
              <h1 className="fs-4 pt-3">Edit Listing</h1>
            </div>
            <MarginBottom className="mb-space-1" />
            <div className="row">
              <form onSubmit={handleUpdate} className="addpropertyform">
                {/* Title */}
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Title*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </div>
                <div className="row g-4 mb-3">
                  {imagePreview?.map((item, index) => (
                    <div
                      key={index}
                      className="col-lg-4"
                      style={{ position: "relative" }}
                    >
                      <span
                        className="badge bg-danger"
                        style={{
                          position: "absolute",
                          top: "-0.5vmax",
                          right: "0",
                          cursor: "pointer",
                          fontSize: "1vmax",
                          zIndex: 1,
                        }}
                        onClick={() => {
                          const index = imagePreview.indexOf(item);
                          const newImagePreview = [...imagePreview];
                          newImagePreview.splice(index, 1);
                          const indx = photos.indexOf(item);
                          const newPhotos = [...photos];
                          newPhotos.splice(indx, 1);
                          setPhotos(newPhotos);
                          setImagePreview(newImagePreview);
                        }}
                      >
                        X
                      </span>
                      <img
                        src={item}
                        alt=""
                        className="card"
                        style={{
                          height: "10vmax",
                          width: "100%",
                          aspectRatio: "auto",
                        }}
                      />
                    </div>
                  ))}
                </div>
                {/* Upload Photos */}
                <div className="mb-3 ">
                  <label className="form-label">Upload Photos*</label>
                  <ImageVideoUpload
                    handleupload={async (e) => {
                      
                        const formData = new FormData();
                        formData.append("photos", e.target?.files[0]);

                        const res = await axios.post(
                          `${url}/api/property/uploadmedia`,
                          formData,
                          {
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          }
                        );
                      

                      const uploadedDocuments = res.data.map((item) => item);
                        
                        console.log(uploadedDocuments);
                     
                      setPhotos([...photos, ...uploadedDocuments]);

                      // setPhotos([...photos, e.target?.files[0]]);
                      if (
                        e.target.files[0].type === "image/jpeg" ||
                        e.target.files[0].type === "image/png" ||
                        e.target.files[0].type === "image/jpg"
                      ) {
                        const reader = new FileReader();
                        reader.onload = () => {
                          if (reader.readyState === 2) {
                            setImagePreview([...imagePreview, reader.result]);
                          }
                        };
                        const imgulr = reader.readAsDataURL(e.target.files[0]);
                        setImagePreview([...imagePreview, imgulr]);
                      }
                    }}
                    type={"image"}
                  />
                </div>

                {/* <div className="row g-4 mb-3">
                  {videoPreview?.map((item, index) => (
                    <div
                      key={index}
                      className="col-lg-4"
                      style={{ position: "relative" }}
                    >
                      <span
                        className="badge bg-danger"
                        style={{
                          position: "absolute",
                          top: "-0.5vmax",
                          right: "0",
                          cursor: "pointer",
                          fontSize: "1vmax",
                          zIndex: 1,
                        }}
                        onClick={() => {
                          const index = videoPreview.indexOf(item);
                          const newVideoPreview = [...videoPreview];
                          newVideoPreview.splice(index, 1);
                          setVideoPreview(newVideoPreview);
                        }}
                      >
                        X
                      </span>
                      <video
                        src={item}
                        alt=""
                        className="card"
                        style={{
                          height: "10vmax",
                          width: "100%",
                          aspectRatio: "auto",
                        }}
                        controls
                      />
                    </div>
                  ))}
                </div> */}
                {/* Upload Video */}
                {/* <div className="mb-3 ">
                  <label className="form-label">Upload Videos</label>
                  <ImageVideoUpload
                      handleupload={async (e) => {
                      
                      const formData = new FormData();
                      formData.append("videos", e.target?.files[0]);

                      const res = await axios.post(
                        `${url}/api/property/uploadmedia`,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );
                    

                    const uploadedDocuments = res.data.map((item) => item);
                      
                      console.log(uploadedDocuments);
                   
                    // setPhotos([...photos, ...uploadedDocuments]);

                   

                      setVideos([...videos, ...uploadedDocuments]);
                      if (
                        e.target.files[0]?.type === "video/mp4" ||
                        e.target.files[0]?.type === "video/mov" ||
                        e.target.files[0]?.type === "video/avi" ||
                        e.target.files[0]?.type === "video/webm"
                      ) {
                        const reader = new FileReader();
                        reader.onload = () => {
                          if (reader.readyState === 2) {
                            setVideoPreview([...videoPreview, reader.result]);
                          }
                        };
                        const vidurl = reader.readAsDataURL(e.target.files[0]);
                        setVideoPreview([...videoPreview, vidurl]);
                      }
                    }}
                  />
                </div> */}

                {/* Property For Sale/Rent Dropdown */}
                <div className="mb-3">
                  <label htmlFor="propertyFor" className="form-label">
                    Property For*
                  </label>
                  <select
                    className="form-select"
                    id="propertyFor"
                    onChange={(e) => setPropertyFor(e.target.value)}
                    value={propertyFor}
                    required
                  >
                    <option value="">Select Property For</option>
                    <option value="sale">For Sale</option>
                    <option value="rent">For Rent</option>
                  </select>
                </div>

                {/* lease status */}
                <div className="mb-3">
                  <label htmlFor="propertyFor" className="form-label">
                    Lease Status
                  </label>

                  <input
                    type="checkbox"
                    id="leaseStatus"
                    name="leaseStatus"
                    value="leaseStatus"
                    className="form-check-input ms-2 mt-1"
                    onChange={(e) => setIsLeased(e.target.checked)}
                    checked={isLeased}
                  />
                </div>

                {/* Property Type Dropdown */}
                <div className="mb-3">
                  <label htmlFor="propertyType" className="form-label">
                    Property Type*
                  </label>
                  <select
                    className="form-select"
                    id="propertyType"
                    onChange={(e) => setPropertyType(e.target.value)}
                    value={propertyType}
                    required
                  >
                    <option value="">Select Property Type</option>
                    <option value="Detached">Detached </option>
                    <option value="Semi detached">Semi detached</option>
                    <option value="Townhouse">Townhouse </option>
                    <option value="Townhouse Condo">Townhouse Condo</option>
                    <option value="Condo apartment">Condo apartment</option>
                    <option value="Rental apartment">Rental apartment</option>
                    <option value="Multiplex">Multiplex</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                {/* Bedrooms */}
                <div className="mb-3">
                  <label htmlFor="Bedrooms" className="form-label">
                    Bedrooms*
                  </label>
                  {/* <input type="text" className="form-control" id="Bedrooms" onChange={(e) => setBedrooms(e.target.value)} /> */}
                  <select
                    className="form-select"
                    id="Bathrooms"
                    onChange={(e) => setBedrooms(e.target.value)}
                    value={bedrooms}
                    required
                  >
                    <option value="">Select Bedrooms</option>
                    <option value="1">1 Bedroom</option>
                    <option value="2">2 Bedrooms</option>
                    <option value="3">3 Bedrooms</option>
                    <option value="4">4 Bedrooms</option>
                    <option value="5">5 Bedrooms</option>
                    <option value="6">6 Bedrooms</option>
                    <option value="7">7 Bedrooms</option>
                    <option value="8">8 Bedrooms</option>
                    <option value="9">9 Bedrooms</option>
                    <option value="10">10 Bedrooms</option>
                  </select>
                </div>
                {/* Bathrooms */}
                <div className="mb-3">
                  <label htmlFor="Bathrooms" className="form-label">
                    Bathrooms*
                  </label>
                  {/* <input type="text" className="form-control" id="Bathrooms" onChange={(e) => setBathrooms(e.target.value)} /> */}
                  <select
                    className="form-select"
                    id="Bathrooms"
                    onChange={(e) => setBathrooms(e.target.value)}
                    value={bathrooms}
                    required
                  >
                    <option value="">Select Bathrooms</option>
                    <option value="1">1 Bathroom</option>
                    <option value="1.5">1.5 Bathrooms</option>
                    <option value="2">2 Bathrooms</option>
                    <option value="2.5">2.5 Bathrooms</option>
                    <option value="3">3 Bathrooms</option>
                    <option value="3.5">3.5 Bathrooms</option>
                    <option value="4">4 Bathrooms</option>
                  </select>
                </div>
                {/* Price */}
                <div className="mb-3">
                  <label htmlFor="Price" className="form-label">
                    Price*
                  </label>
                  <div className="input-group">
                    <span className="input-group-text">$</span>
                    <input
                      type="number"
                      className="form-control"
                      id="Price"
                      onChange={(e) => setPrice(e.target.value)}
                      value={price}
                      required
                    />
                  </div>
                </div>
                {/* Property Address */}
                <div className="mb-3">
                  <label htmlFor="Address" className="form-label">
                    Property Address*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Address"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    required
                  />
                </div>
                {/* Property Description */}
                <div className="mb-3">
                  <label htmlFor="Description" className="form-label">
                    Property Description
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="Description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    rows={description.split(".").length + 1}
                  />
                </div>

                {/* Advanced Details */}
                <h2 className="fs-5 mt-4">Aditional Details</h2>
                {/* <h6 className="fs-6 mb-3">Optional</h6> */}

                {/* Square Feet */}
                <div className="mb-3">
                  <label htmlFor="squareFeet" className="form-label">
                    Square Feet*
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="squareFeet"
                    onChange={(e) => setSquareFeet(e.target.value)}
                    min={200}
                    max={8000}
                    value={squareFeet}
                    required
                  />
                </div>

                {/* Laundry Type Dropdown */}
                <div className="mb-3">
                  <label htmlFor="laundryType" className="form-label">
                    Laundry Type
                  </label>
                  <select
                    className="form-select"
                    id="laundryType"
                    onChange={(e) => setLaundryType(e.target.value)}
                    value={laundryType}
                  >
                    <option value="In-unit laundry">In-unit laundry</option>
                    <option value="Laundry in building">
                      Laundry in building
                    </option>
                    <option value="Laundry available">Laundry available</option>
                    <option value="None">None</option>
                  </select>
                </div>
                {/* Parking Type Dropdown */}
                <div className="mb-3">
                  <label htmlFor="parkingType" className="form-label">
                    Parking Type*
                  </label>
                  <select
                    className="form-select"
                    id="parkingType"
                    onChange={(e) => setParkingType(e.target.value)}
                    value={parkingType}
                    required
                  >
                    <option>Garage parking</option>
                    <option>Street Parking</option>
                    <option>Off-street parking</option>
                    <option>Parking available</option>
                    <option>None</option>
                  </select>
                </div>
                {/* Parking Spots Dropdown */}
                <div className="mb-3">
                  <label htmlFor="parkingSpots" className="form-label">
                    Parking Spots
                  </label>
                  <select
                    className="form-select"
                    id="parkingSpots"
                    onChange={(e) => setParkingSpots(e.target.value)}
                    value={parkingSpots}
                  >
                    <option value="">Select Parking Spots</option>
                    <option value="0" selected={true}>
                      0
                    </option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </div>
                {/* Air Conditioning Type Dropdown */}
                <div className="mb-3">
                  <label htmlFor="airconditioning" className="form-label">
                    Air Conditioning
                  </label>
                  <select
                    className="form-select"
                    id="airconditioning"
                    onChange={(e) => setAirConditioning(e.target.value)}
                    value={airConditioning}
                  >
                    <option value="Central AC">Central AC</option>
                    <option value="AC available">AC available</option>
                    <option value="None">None</option>
                  </select>
                </div>
                {/* Heating Type Dropdown */}
                <div className="mb-3">
                  <label htmlFor="heatingType" className="form-label">
                    Heating Type
                  </label>
                  <select
                    className="form-select"
                    id="heatingType"
                    onChange={(e) => setHeatingType(e.target.value)}
                    value={heatingType}
                  >
                    <option value="Central heating">Central heating</option>
                    <option value="Electric heating">Electric heating</option>
                    <option value="Gas heating">Gas heating</option>
                    <option value="Radiator heating">Radiator heating</option>
                    <option value="Heating available">Heating available</option>
                    <option value="None">None</option>
                  </select>
                </div>

                {/* Amenities Multi-select Dropdown */}
                <div className="mb-3">
                  <label htmlFor="amenities" className="form-label">
                    Amenities
                  </label>

                  <CreatableSelect
                    isMulti
                    options={options}
                    placeholder="Select Amenities"
                    value={amenities}
                    onChange={(e) => setAmenities(e)}
                    // onChange={(e) => setAmenities(e.map((item) => item.value))}
                  />
                </div>
                <div className="mb-3">
                  {/* Create Button */}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{ marginRight: "2vmax" }}
                  >
                    Update
                  </button>
                  <button
                    className="btn"
                    style={{ background: "red", color: "#fff" }}
                    onClick={handleDelete}
                  >
                    <MdDeleteOutline
                      style={{
                        fontSize: "20px",
                        marginRight: "5px",
                        marginTop: "-4px",
                      }}
                    />
                    Delete
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditProperty;

export const ImageVideoUpload = ({ handleupload, type }) => {
  return (
    <input
      type="file"
      className="form-control"
      id="upload"
      onChange={handleupload}
      accept={type === "image" ? "image/*" : "video/*"}
    />
  );
};
