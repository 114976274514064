import React from "react";
import "./Footer.scss";
import { useForm } from "react-hook-form";
import { InputText, Button, Form } from "../../Elements";
import ErrorDisplay from "../ErrorDisplay";
import { API_ENDPOINTS } from "../../../constants/constants";
import useApi from "../../../hooks/useAPI";

const Footer = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });

  const { createEmailWithZipCode } = useApi();

  const onSubmit = (data) => {
    createEmailWithZipCode(API_ENDPOINTS.signUp, "POST", data);
  };

  return (
    <div className="footer-component-new">
      <div className="footer-content">

          <div className="bid-footer-fields">

          </div>
      </div>
    </div>
  );
};

export default Footer;
