import React, { useState } from "react";
import axios from "axios";
import { ImAttachment } from "react-icons/im";
import { FlexRow } from "../../Layouts";
import useApi from "../../../hooks/useAPI";
import { ChangeEvent } from "react";

const FileUpload = (props) => {
  const [file, setFile] = useState();
  const { uploadFile, loading, data, error } = useApi();

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  return (
    <div className="input-box" style={{ height: "3rem" }}>
      {/*       <FlexRow style={{ width: "92%" }}>
       */}{" "}
      {/*      <p>
          <ImAttachment className="attachIcons" />
        </p>
        <span>Attach Requirements Package (PDF)*</span> */}
      <input type="file" onChange={handleFileChange} />
      <div>{file && `${file.name} - ${file.type}`}</div>
      {/*       </FlexRow>
       */}{" "}
    </div>
  );
};

export default FileUpload;
