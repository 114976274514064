import React from "react";
import "./FlexCol.scss";

const FlexCol = (props) => {
  const { style, className, children } = props;
  return (
    <div className={`flex-col ${className}`} style={style}>
      {children}
    </div>
  );
};
export default FlexCol;
