import React,{useRef} from "react";
import "./index.scss";
import Navbar from "../NavBar/Index";
import { useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { url } from "../../constData";
import ScrollTop from "../../hooks/ScrollTop";

function LoginPage(props) {
  const navigate = useNavigate();
  const loginRef = useRef(null);
  // const login = useGoogleLogin({
  //   onSuccess: (tokenResponse) => {
  //     // console.log(tokenResponse);
  //     sessionStorage.setItem("token", tokenResponse.access_token);
  //     sessionStorage.setItem("user", JSON.stringify(tokenResponse.profileObj));

  //     // console.log("user",user);
  //     // navigate('/');
  //   },
  // });



 

 
  return (
    <>
      <ScrollTop/>
      <Navbar />
      <section className="header-section">
        <div class="header-bg">
          <div className="login-wrapper">
            <h1>Landlords</h1>
            <div className="btn-wrapper">
              {/* <button className="g-btn">
                Enter with Google
              </button> */}

              <GoogleLogin
              ref={loginRef}
                onSuccess={async (credentialResponse) => {
                  // console.log(credentialResponse);
                  const user = jwtDecode(credentialResponse.credential);
                  // console.log(user);
                  sessionStorage.setItem("token", credentialResponse.credential);
                  sessionStorage.setItem("Google", JSON.stringify(user));
                  const res = await axios.post(`${url}/api/user/auth`, {
                    email: user.email,
                    name: user.name,
                    profilePic: user.picture,
                  });
                  sessionStorage.setItem("user", JSON.stringify(res.data.existingUser));
                  navigate("/");

                }}
                onError={() => {
                  console.log("Login Failed");
                }}
                id = 'login-btn'
              />

              
              
              {/* <button className='f-btn'>Enter with Facebook</button> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LoginPage;
