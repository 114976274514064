// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bidBtn, .white, .blue {
  width: 100%;
  background-color: #fff;
  color: #329fd4;
  border-radius: 5px;
  height: 35px;
  border: 1px solid #fff;
  padding: 0px;
  text-align: center;
  font-family: "Graphik-Medium";
  font-size: 12px;
  font-weight: normal;
}
.bidBtn:hover, .white:hover, .blue:hover {
  background-color: #329FD4;
  color: #ffffff;
}
.bidBtn:focus, .white:focus, .blue:focus {
  background-color: #329FD4;
  color: #ffffff;
}

.blue {
  background-color: #329FD4;
}

.white {
  background-color: #ffffff;
  width: 274px;
  color: #329FD4;
}
@media only screen and (max-width: 576px) {
  .white {
    width: 100%;
    margin-top: 0.6rem;
  }
}
.white:hover {
  background-color: #ffffff;
  color: #329FD4;
}
.white:active {
  background-color: #ffffff;
  color: #329FD4;
}`, "",{"version":3,"sources":["webpack://./src/components/Elements/Button/Button.scss","webpack://./src/components/variables.scss"],"names":[],"mappings":"AA0BA;EAZE,WAZQ;EAaR,sBAVW;EAWX,cAVQ;EAWR,kBAVgB;EAWhB,YAfS;EAgBT,sBAfS;EAgBT,YAZU;EAaV,kBAZQ;EAaR,6BClBW;EDmBX,eAbW;EAcX,mBAba;AACf;AAgBE;EACE,yBC5BQ;ED6BR,cC5BI;ADcR;AAgBE;EACE,yBChCQ;EDiCR,cChCI;ADkBR;;AAiBA;EAEE,yBCtCU;ADuBZ;;AAiBA;EAEE,yBCzCM;ED0CN,YAAA;EACA,cC5CU;AD6BZ;AAgBE;EALF;IAMI,WAAA;IACA,kBAAA;EAbF;AACF;AAcE;EACE,yBCjDI;EDkDJ,cCnDQ;ADuCZ;AAcE;EACE,yBCrDI;EDsDJ,cCvDQ;AD2CZ","sourcesContent":["@import \"../../../components/variables.scss\";\n@mixin button(\n  $width: 100%,\n  $height: 35px,\n  $border: 1px solid #fff,\n  $bg-color: #fff,\n  $color: #329fd4,\n  $border-radius: 5px,\n  $padding: 0px,\n  $align: center,\n  $fontSize: 12px,\n  $fontWeight: normal,\n  $family: $mediumFont\n) {\n  width: $width;\n  background-color: $bg-color;\n  color: $color;\n  border-radius: $border-radius;\n  height: $height;\n  border: $border;\n  padding: $padding;\n  text-align: $align;\n  font-family: $family;\n  font-size: $fontSize;\n  font-weight: $fontWeight;\n}\n.bidBtn {\n  @include button;\n  &:hover {\n    background-color: $blueColor;\n    color: $white;\n  }\n  &:focus {\n    background-color: $blueColor;\n    color: $white;\n  }\n}\n.blue {\n  @extend .bidBtn;\n  background-color: $blueColor;\n}\n.white {\n  @extend .bidBtn;\n  background-color: $white;\n  width: 274px;\n  color: $blueColor;\n  @media only screen and (max-width: 576px) {\n    width: 100%;\n    margin-top: 0.6rem;\n  }\n  &:hover {\n    background-color: $white;\n    color: $blueColor;\n  }\n  &:active {\n    background-color: $white;\n    color: $blueColor;\n  }\n}\n","$primaryColor: hsl(0, 0%, 22%);\n$blueColor: #329FD4;\n$white: #ffffff;\n$black: #000000;\n$mediumFont: \"Graphik-Medium\";\n$regularFont: \"Graphik-Regular\";\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
