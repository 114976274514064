// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-component {
  width: 100%;
  margin: auto;
}
.footer-component .horizontal-line {
  width: 80%;
  height: 1px;
  margin: auto;
  opacity: 0.2;
  border: solid 0.5px #fff;
}
.footer-component .footer-text {
  width: 100%;
  margin: auto;
  font-style: normal;
  line-height: 1.83;
  font-size: 11px;
  letter-spacing: normal;
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-family: "Graphik-Regular";
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AAAE;EACE,UAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,wBAAA;AAEJ;AACE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,sBAAA;EACA,aAAA;EACA,8BAAA;EACA,WAAA;EACA,8BAAA;AACJ","sourcesContent":[".footer-component {\n  width: 100%;\n  margin: auto;\n  .horizontal-line {\n    width: 80%;\n    height: 1px;\n    margin: auto;\n    opacity: 0.2;\n    border: solid 0.5px #fff;\n  }\n\n  .footer-text {\n    width: 100%;\n    margin: auto;\n    font-style: normal;\n    line-height: 1.83;\n    font-size: 11px;\n    letter-spacing: normal;\n    display: flex;\n    justify-content: space-between;\n    color: #fff;\n    font-family: \"Graphik-Regular\";\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
