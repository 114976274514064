// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frently-container {
  width: 83%;
  margin: auto auto;
}
@media (min-width: 1400px) {
  .frently-container {
    max-width: 1320px;
    margin: auto;
  }
}
@media only screen and (max-width: 576px) {
  .frently-container {
    width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layouts/Container/Container.scss"],"names":[],"mappings":"AAAA;EAME,UAAA;EACA,iBAAA;AAJF;AAFE;EADF;IAEI,iBAAA;IACA,YAAA;EAKF;AACF;AADE;EARF;IASI,UAAA;EAIF;AACF","sourcesContent":[".frently-container {\n  @media (min-width: 1400px) {\n    max-width: 1320px;\n    margin: auto;\n  }\n\n  width: 83%;\n  margin: auto auto;\n  @media only screen and (max-width: 576px) {\n    width: 90%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
