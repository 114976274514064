import React from "react";
import "./ImageSrcset.scss";

const ImageSrcSet = (props) => {
  const lg = props.srcSet + "@3x.png";
  const md = props.srcSet + "@2x.png";
  const sm = props.srcSet + ".png";
  return (
    <>
      <img
        className={props.className}
        srcSet={`${lg} 1024w, ${md} 768w,${sm} 576w`}
        src={`${props.srcSet}.png`}
        sizes="(max-width:1024px) 1024px,(max-width:768px) 768px,(max-width:640px) 640px"
        alt={props.alt}
        style={props.style}
      />
    </>
  );
};
export default ImageSrcSet;
