import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer-component">
      <div className="horizontal-line"></div>
      <div style={{ width: "80%", margin: "auto", padding: "20px 0"}}>
        <div className="footer-text">
          <div style={{ alignSelf: "flex-start" }}>© Frently Inc. All Rights Reserved.</div>
          <div style={{ alignSelf: "flex-end" }}>support@frently.ca</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
