import React from "react";
import "./Header.scss";
import { ImageSrcSet } from "../../Elements";

const Header = (props) => {
  const { style, className } = props;
  return (
    <div className={`bid-logo ${className}`} style={style}>
      <ImageSrcSet
        alt="logo"
        srcSet=""
        fileExtention="png"
        style={{ width: "195px", height: "25px", marginBottom: "15px" }}
      />
    </div>
  );
};
export default Header;
