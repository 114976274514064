// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-fluid {
  width: 100%;
  background-image: linear-gradient(149deg, #329fd4 -28%, #004566 95%);
  padding: 3.5rem 0px;
}
@media only screen and (max-width: 576px) {
  .container-fluid {
    padding: 1rem 0rem 1rem 0rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layouts/ContainerFluid/ContainerFluid.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EAEA,oEAAA;EACC,mBAAA;AAAL;AACK;EALL;IAMQ,4BAAA;EAEN;AACF","sourcesContent":[".container-fluid{\n    width:100%;\n    // background-image: linear-gradient(144deg, #547db8 27%, #445c7e 97%);\n    background-image: linear-gradient(149deg, #329fd4 -28%, #004566 95%);\n     padding: 3.5rem 0px;\n     @media only screen and (max-width: 576px) {\n        padding: 1rem 0rem 1rem 0rem;\n      }    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
