import { useEffect, useState, useCallback } from "react";
import { makeApiCall } from "../api/api";
import { API_ENDPOINTS } from "../constants/constants";

const useApi = (url) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const createEmailWithZipCode = useCallback(
    async (fetchFrom, method, data) => {
      makeApiCall(fetchFrom, method, JSON.stringify(data))
        .then((response) => {
          console.log("response", response);
          if (response.status === 201) {
            setData("Registered Successfully");
          }
        })
        .catch((error) => {
          console.log("error", error);
          setError(error.response.data);
        });
    },
    [data]
  );

  const createOnboardingForm = useCallback(
    async (fetchFrom, method, data) => {
      makeApiCall(fetchFrom, method, JSON.stringify(data))
        .then((response) => {
          console.log("response", response);
          if (response.status === 201) {
            setData("Onboarded Successfully");
          }
        })
        .catch((error) => {
          console.log("Error", error);
          setError(error.response.data);
        });
    },
    [data]
  );
  const uploadFile = useCallback(async (fetchFrom, method, data) => {
    makeApiCall(fetchFrom, method, JSON.stringify(data))
      .then((response) => {
        if (response.data.responseCode === 200) {
          setData("Uploaded Successfully");
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  }, []);

  return {
    createEmailWithZipCode,
    uploadFile,
    createOnboardingForm,
    loading,
    data,
    error,
  };
};

export default useApi;
