import React from 'react'

function ScrollTop() {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
  return (
    <></>
  )
}

export default ScrollTop