import {
  MegaTitle,
  CustomHeader,
  PrimaryHeader,
  Paragraph,
} from "./Typography";
import Button from "./Button";
import Form from "./Form";
import { InputText, Accordian } from "./InputControls";
import ImageSrcSet from "./Image";
import MarginBottom from "./MarginBottom";
import DownloadPDF from "./DownloadPdf";
import FileUpload from "./FileUpload";

export {
  MegaTitle,
  CustomHeader,
  PrimaryHeader,
  Paragraph,
  Button,
  InputText,
  Form,
  ImageSrcSet,
  MarginBottom,
  Accordian,
  DownloadPDF,
  FileUpload,
};
