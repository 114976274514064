import React, { useState, useEffect } from "react";
import "./OperatorForm.scss";
import { InputText, Button, Form } from "../../Elements";
import { useForm } from "react-hook-form";
import ErrorDisplay from "../ErrorDisplay";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import useApi from "../../../hooks/useAPI";
import { API_ENDPOINTS } from "../../../constants/constants";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import DownloadPDF from "../../Elements/DownloadPdf";
import FlexRow from "../../Layouts/FlexRow";

const list = [
  {
      "id": 1,
      "name": "Home"
  },
  {
      "id": 2,
      "name": "Apartment"
  },
  {
      "id": 3,
      "name": "Rooms in a Home"
  },
  {
      "id": 4,
      "name": "Multiplex"
  },
  {
      "id": 5,
      "name": "Condominium"
  },
  {
      "id": 6,
      "name": "Other"
  }
];

const OperatorForm = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });
  const { createOnboardingForm, vehicleList, error, data } =
    useApi();

  const [file, setFile] = useState();
  const [lang, setLang] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [errorOnboard, setErrorOnboard] = useState();
  const [successOnboard, setSuccessOnboard] = useState();

  useEffect(() => {
    setErrorOnboard(error);
    setSuccessOnboard("");
  }, [error]);

  useEffect(() => {
    setErrorOnboard("");
    setSuccessOnboard(data);
  }, [data]);

  useEffect(() => {
    setVehicleData(list);
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleCheckBox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setLang((prev) => [...prev, value]);
    } else {
      setLang((prev) => prev.filter((x) => x !== value));
    }
  };

  // console.log(vehicleData);

  const handleSetRecaptcha = (captchaData) => {
    console.log("captchaData", captchaData);
    setValue("g-recaptcha-response", captchaData);
  };

  const onSubmit = (data) => {
    console.log(data);

    try {
      emailjs.send("service_xe4xecg", "template_36oh684", data, "F6utohl8A93_qSXWR").then((res) => {

        console.log("form submitted successfully !", res.status);

        if (res.status === 200) {
          toast.success('Submitted successfully!', {
            position: 'top-right',
            autoClose: 3000, // Close the notification after 3 seconds (adjust as needed)
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    } catch (error) {
      console.log("e", error);
    }


    // data.package_document = file;
    // createOnboardingForm(API_ENDPOINTS.onboardForm, "POST", data);
  };

  return (
    <Form className="frently-operator-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="frently-operator-fields">
        <div className="input-error-wrapper">
          <InputText
            type="text"
            placeholder="Postal Code*"
            className="marginSpace"
            register={register("zipcode", {
              required: "Postal Code is required",
              minLength: {
                value: 5,
                message: "Postal Code should be 5 digit.",
              },
            })}
            aria-invalid={errors.zipcode ? "true" : "false"}
            maxLength={12}
          />
          {errors.zipcode && (
            <ErrorDisplay>{errors.zipcode?.message}</ErrorDisplay>
          )}
        </div>
        <div className="input-error-wrapper">
          <InputText
            type="text"
            placeholder="First Name*"
            className="marginSpace"
            register={register("first_name", {
              required: "First Name is required.",
            })}
            aria-invalid={errors.first_name ? "true" : "false"}
          />
          {errors.first_name && (
            <ErrorDisplay>{errors.first_name?.message}</ErrorDisplay>
          )}
        </div>
        <div className="input-error-wrapper">
          <InputText
            type="text"
            placeholder="Last Name*"
            className="marginSpace"
            register={register("last_name", {
              required: "Last Name is required.",
            })}
            aria-invalid={errors.last_name ? "true" : "false"}
          />
          {errors.last_name && (
            <ErrorDisplay>{errors.last_name?.message}</ErrorDisplay>
          )}
        </div>

        <div className="input-error-wrapper">
          <InputText
            type="text"
            placeholder="Preferred Contact Email*"
            className="marginSpace"
            register={register("contact_email", {
              required: {
                value: true,
                message: "Email address is required",
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Email address is required",
              },
            })}
            aria-invalid={errors.contact_email ? "true" : "false"}
          />
          {errors.contact_email && (
            <ErrorDisplay>{errors.contact_email?.message}</ErrorDisplay>
          )}
        </div>
        <div className="input-error-wrapper">
          <InputText
            type="text"
            placeholder="Preferred Contact Phone Number*"
            className="marginSpace"
            maxLength={12}
            minLength={10}
            register={register("contact_phone_number", {
              required: "Contact Number is required",
              minLength: {
                value: 10,
                message: "Contact Number should be 10 digit",
              },
            })}
            aria-invalid={errors.contact_phone_number ? "true" : "false"}
          />
          {errors.contact_phone_number && (
            <ErrorDisplay>{errors.contact_phone_number?.message}</ErrorDisplay>
          )}
        </div>

        <div className="input-error-wrapper">
          <InputText
            type="text"
            placeholder="Preferred Address*"
            className="marginSpace"
            register={register("preferred_address", {
              required: "Preferred Address is required",
            })}
            aria-invalid={errors.preferred_address ? "true" : "false"}
          />
          {errors.preferred_address && (
            <ErrorDisplay>{errors.preferred_address?.message}</ErrorDisplay>
          )}
        </div>

        {/* <div className="input-error-wrapper">
          <InputText
            type="text"
            placeholder="Notes"
            className="marginSpace"
          />
        </div> */}

        {/* <DownloadPDF
          style={{ padding: "0px", alignItems: "center", height: "3rem" }}
          className="download-pdf"
          fileName="dummy.pdf"
          descriptionL1="Download our Requirements Package"
          descriptionL2=" Fill it in, and attach it below*"
        />
        <div className="input-error-wrapper">
          <div className="input-box" style={{ padding: "0.5rem" }}>
            <FlexRow>
              <div className="icon-text">
                <p className="icon-container">
                  <span className="icon-attach"></span>
                </p>
                <input
                  type="file"
                  id="fileupload"
                  style={{ display: "none" }}
                  name="package_document"
                  {...register("package_document", {
                    required: " Please Attach Requirements Package",
                  })}
                  onChange={handleFileChange}
                />
                <label
                  style={{ padding: "2px", cursor: "pointer", width: "88%" }}
                  htmlFor="fileupload"
                >
                  {file
                    ? `${file.name} - ${file.type}`
                    : "Attach Requirements Package (PDF)*"}
                </label>
              </div>
            </FlexRow>
          </div>
          {errors.package_document && (
            <ErrorDisplay>{errors.package_document?.message}</ErrorDisplay>
          )}
        </div> */}


        <div className="input-error-wrapper ">
          <div className="inputDiv marginSpace select-hover">
            <div className="form-checkbox ">
              <div className="title">
              What type of properties do you have to list?
              </div>
              <div className="form-checkboxs">
                <ul className="checkbox checkbox-flex">
                  {vehicleData.map((x, i) => (
                    <li
                      key={i}
                      style={{
                        alignItems: "baseline",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="li-flex">
                        <input
                          type="checkbox"
                          value={x.id}
                          onChange={handleCheckBox}
                          className="input-checkbox"
                          {...register("vehicle_type_ids", {
                            required: "Please select any one.",
                          })}
                        />
                        <label className="form-label">{x.name}</label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {errors.vehicle_type_ids && (
            <ErrorDisplay>{errors.vehicle_type_ids?.message}</ErrorDisplay>
          )}
        </div>
      </div>

      {/* <div
        id="captcha"
        style={{ marginBottom: "0px", padding: "0px", width: "274px" }}
      >
        <ReCAPTCHA
          className="g-recaptcha"
          {...register("g-recaptcha-response", {
            required: "Please select the captcha.",
          })}
          name="g-recaptcha-response"
          sitekey="6LcpIdkjAAAAAC6dHQe1pgDZ-TTHiXjh6mm-85qg"
          onChange={handleSetRecaptcha}
        />
        {errors["g-recaptcha-response"] && (
          <ErrorDisplay style={{ padding: "0px", margin: "0.2rem" }}>
            {errors["g-recaptcha-response"].message}
          </ErrorDisplay>
        )}
      </div> */}

      <Button type="submit" className="bidBtn white">
       Sign Up to List for Free
      </Button>

      <ToastContainer 
          position={false}
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

      <div className="server-error">
        {successOnboard?.length > 0 && (
          <ErrorDisplay style={{ color: "green" }}>
            {successOnboard}
          </ErrorDisplay>
        )}
        {errorOnboard?.length > 0 && (
          <ErrorDisplay className="error-red">
            {errorOnboard?.message}
          </ErrorDisplay>
        )}
      </div>
    </Form>
  );
};

export default OperatorForm;
