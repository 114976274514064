// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-text, .error-red {
  color: #faea4f;
  padding: 0.6rem 0px 0px 0px;
  font-family: "Graphik-Medium";
  font-size: 11px;
}

.error-red {
  color: red;
  padding: 0.3rem 0px 0px 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/Templates/ErrorDisplay/ErrorDisplay.scss"],"names":[],"mappings":"AAMA;EALI,cADoB;EAEpB,2BAFuC;EAGvC,6BAHqF;EAIrF,eAJsE;AAK1E;;AAIA;EAEI,UAAA;EACA,2BAAA;AAFJ","sourcesContent":["@mixin ErrorMsg($color: #faea4f, $padding: 0.6rem 0px 0px 0px, $fontSize: 11px, $family: \"Graphik-Medium\") {\n    color: $color;\n    padding: $padding;\n    font-family: $family;\n    font-size: $fontSize;\n}\n.error-text {\n    @include ErrorMsg;\n}\n.error-red {\n    @extend .error-text;\n    color: red;\n    padding: 0.3rem 0px 0px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
