import React, { useEffect, useState } from "react";
import "./SignUp.scss";
import { InputText, Button, Form } from "../../Elements";
import { useForm } from "react-hook-form";
import ErrorDisplay from "../ErrorDisplay";
import emailjs from "@emailjs/browser";
import useApi from "../../../hooks/useAPI";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


const SignUp = (props) => {
  const { inputClass, parentClass } = props;
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });
  const { error, data } = useApi();

  const onSubmit = (data) => {
    try {
      emailjs.send("service_xe4xecg", "template_vkd5txg", data, "F6utohl8A93_qSXWR").then((res) => {

        console.log("form submitted successfully !", res.status);

        if (res.status === 200) {
          toast.success('Form submitted successfully!', {
            position: 'top-right',
            autoClose: 3000, // Close the notification after 3 seconds (adjust as needed)
            hideProgressBar: false,
            closeOnClick: true,
            newestOnTop: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    } catch (error) {
      console.log("e", error);
    }
  };
  useEffect(() => {
    console.log("error", error);
    setErrorMsg(error);
    setSuccessMsg("");
  }, [error]);

  useEffect(() => {
    setErrorMsg("");
    setSuccessMsg(data);
  }, [data]);

  return (
    <Form className="frently-operator-signup" onSubmit={handleSubmit(onSubmit)}>
      <div className="frently-signup-fields">
        <div className={`input-box ${parentClass}`}>
          <InputText
            className={inputClass}
            type="text"
            placeholder="Email"
            register={register("contact_email", {
              required: {
                value: true,
                message: "Email address is required",
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid Email",
              },
            })}
            aria-invalid={errors.contact_email ? "true" : "false"}
          />
          {errors.contact_email && <ErrorDisplay className="error-red">{errors.contact_email?.message}</ErrorDisplay>}
        </div>
        <div className={`input-box ${parentClass}`}>
          <InputText
            type="text"
            placeholder="Phone Number"
            className={inputClass}
            maxLength={12}
            minLength={10}
            register={register("phone_number", {
              required: "Phone Number is required",
              minLength: {
                value: 10,
                message: "Phone Number should be 10 digit",
              },
            })}
            aria-invalid={errors.phone_number ? "true" : "false"}
          />
          {errors.phone_number && <ErrorDisplay className="error-red">{errors.phone_number?.message}</ErrorDisplay>}
        </div>
      </div>

      <Button type="submit" className="sign-up-button">
        Sign Up to List for Free
      </Button>

      <div className="server-error">
        {successMsg?.length > 0 && <ErrorDisplay style={{ color: "green" }}>{successMsg}</ErrorDisplay>}
        {errorMsg?.length > 0 && <ErrorDisplay className="error-red">{errorMsg?.message}</ErrorDisplay>}
      </div>
    </Form>
  );
};

export default SignUp;
